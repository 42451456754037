import React, { forwardRef } from 'react'
import { VictoryLegend } from 'victory-native'
import { Node } from '@rapid/core-types'
//@ts-expect-error
import RNSpeedometer from 'react-native-speedometer'
import { useChartData } from './util/useChartData'
import { NodeRendererProps } from './types'

export const ChartGaugeNodeRenderer = forwardRef<any, NodeRendererProps<Node>>(
  ({ childNodes, value }: NodeRendererProps<Node>) => {
    const data = useChartData(childNodes)
    const legendArray = data.map(({ x, y, color }) => {
      return {
        name: x,
        y: y,
        symbol: { fill: !color ? '#0000FF' : color },
      }
    })

    const gaugeArray = data.map(({ x, color }) => {
      return {
        name: x,
        labelColor: color == null ? '#0000FF' : color,
        activeBarColor: color == null ? '#0000FF' : color,
      }
    })

    return (
      <>
        {data.length > 0 ? (
          <RNSpeedometer
            value={
              !value || value.value === '' || value.value === 'undefined'
                ? 0
                : parseInt(value.value, 10)
            }
            size={100}
            minValue={0}
            maxValue={100}
            allowedDecimals={0}
            labels={gaugeArray}
          />
        ) : (
          <RNSpeedometer
            value={0}
            size={100}
            minValue={0}
            maxValue={100}
            allowedDecimals={0}
            labels={[{ name: 'Label', labelColor: '#000000' }]}
          />
        )}
        <VictoryLegend
          x={90}
          y={10}
          centerTitle
          orientation="horizontal"
          gutter={15}
          style={{ border: { stroke: 'black' }, title: { fontSize: 20 } }}
          data={legendArray}
        />
      </>
    )
  }
)
