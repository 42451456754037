import React, { forwardRef, useCallback } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import DateTimePicker from '@react-native-community/datetimepicker'
import { InputDateTimePickerNode } from '@rapid/node-type'
import { parseJSON } from 'date-fns'
import { useBooleanValue } from '../../../shared/hooks/useBooleanValue'
import { resolveAppStateContext } from '../../../shared/resolve/appStateContext'
import { NodeRendererProps } from '../types'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

const now = new Date()

export const InputDateTimePickerAndroid = forwardRef<
  any,
  NodeRendererProps<InputDateTimePickerNode>
>(({ node, style }, ref) => {
  const [isDatePickerShown, showDatePicker, hideDatePicker] = useBooleanValue()

  const onChange = useFormFieldChangeHandler(node)

  const value = useFormFieldValue(node)

  const handleOnChange = useCallback(
    (_, selectedDate) => {
      hideDatePicker()

      onChange(selectedDate)
    },
    [onChange, hideDatePicker]
  )

  return (
    <>
      <TouchableOpacity style={style} ref={ref} onPress={showDatePicker}>
        <Text>
          {value instanceof Date
            ? value.toLocaleDateString()
            : typeof value === 'string'
            ? parseJSON(value).toLocaleDateString()
            : ''}
        </Text>
      </TouchableOpacity>

      {isDatePickerShown ? (
        <DateTimePicker
          value={value instanceof Date ? value : now}
          mode="date"
          display="default"
          onChange={handleOnChange}
        />
      ) : null}
    </>
  )
})
