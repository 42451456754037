import { Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AsyncStorageKey } from '@rapid/app-state'

export const removeFromFavorite = async (pageId: string | undefined) => {
  try {
    const storedFavorites = await AsyncStorage.getItem(AsyncStorageKey.Favorites)
    const favorites = storedFavorites && JSON.parse(storedFavorites)

    if (pageId && favorites && Array.isArray(favorites) && favorites.includes(pageId)) {
      const updatedFavorites = favorites.filter((id) => id !== pageId)

      await AsyncStorage.setItem(AsyncStorageKey.Favorites, JSON.stringify(updatedFavorites))
      Alert.alert('Removed from favorites')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
