import { Alert, Platform } from 'react-native'
import * as Calendar from 'expo-calendar'
import { ActionAddToCalendar } from '@rapid/core-types'

export const addToCalendar = async (action: ActionAddToCalendar) => {
  const { status } = await Calendar.requestCalendarPermissionsAsync()

  if (status === 'granted') {
    const defaultCalendar =
      Platform.OS === 'ios'
        ? Calendar.getDefaultCalendarAsync()
        : { isLocalAccount: true, name: 'Event' }
    if (!defaultCalendar) {
      return Alert.alert('Cannot identify default calendar')
    }

    const newCalendarID = await Calendar.createCalendarAsync({
      title: action.title,
      color: 'blue',
      entityType: Calendar.EntityTypes.EVENT,
      sourceId: defaultCalendar?.id,
      source: defaultCalendar,
      name: 'internalCalendarName',
      ownerAccount: 'personal',
      accessLevel: Calendar.CalendarAccessLevel.OWNER,
    })
    await Calendar.createEventAsync(newCalendarID, {
      title: action.title,
      startDate: new Date(action.startDate),
      endDate: new Date(action.endDate),
      timeZone: action.timeZone,
    })
    Alert.alert('Event added to calendar!')
  } else {
    Alert.alert('Calendar permission needed')
  }
}
