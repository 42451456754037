import React, { useEffect, useMemo } from 'react'
import { Route, useNavigation, useRoute } from '@react-navigation/native'
import * as WebBrowser from 'expo-web-browser'
import { exchangeCodeAsync, makeRedirectUri, useAuthRequest } from 'expo-auth-session'
import { OAuth2Config, OAuth2Discovery } from '@rapid/core-types'
import { isPage } from '@rapid/util'
import { useAuthState } from '@rapid/app-state'
import { ApiResponseProvider } from '../../shared/context/ApiResponse'
import { resolvePageDataContext } from '../../shared/resolve/pageDataContext'
import { useApiMapper } from '../../shared/context/ApiMapper'
import { RecursiveRenderer } from '../../renderer/RecursiveRenderer'

WebBrowser.maybeCompleteAuthSession()

const { usePageNodeTreeForPageKey } = resolvePageDataContext()

const createValidOAuthConfig = (config?: OAuth2Config) => {
  if (!config) {
    throw new Error('No config passed')
  }

  const scopes = config.scopes?.map(({ value }) => value)

  const extraParams = config.extraParams?.reduce<Record<string, string>>((acc, { key, value }) => {
    acc[key] = value

    return acc
  }, {})

  const redirectUri = makeRedirectUri({
    native: config.redirectUri,
    path: config.redirectUri.split('://')[1],
  })

  return { ...config, redirectUri, scopes, extraParams }
}

const createDiscoveryConfig = (discovery?: OAuth2Discovery) => {
  if (!discovery) {
    throw new Error('No discovery passed')
  }

  return discovery
}

export const AuthPage = () => {
  const { params } = useRoute<Route<'auth', { serviceId: string; pageId?: string }>>()

  const navigation = useNavigation()

  const tree = usePageNodeTreeForPageKey(params.pageId)

  const { setAuthToken, setAuthUser } = useAuthState()

  const apiMapper = useApiMapper()

  const { servicesById } = apiMapper

  const service = servicesById[params.serviceId]

  const redirectId = isPage(tree?.main) ? tree?.main.meta?.redirectId : null

  const config = useMemo(() => createValidOAuthConfig(service.authentication?.oAuth2?.config), [
    service,
  ])

  const discovery = useMemo(
    () => createDiscoveryConfig(service.authentication?.oAuth2?.discovery),
    [service]
  )

  const [request, response, promptAsync] = useAuthRequest(config, discovery)

  useEffect(() => {
    const handleResponse = async (code: string) => {
      const extraParams = request?.codeVerifier
        ? { ...config.extraParams, code_verifier: request?.codeVerifier }
        : config.extraParams

      const token = await exchangeCodeAsync(
        {
          ...config,
          extraParams: extraParams,
          code,
        },
        discovery
      )

      setAuthToken(service, token)

      if (discovery.userInfoEndpoint) {
        const resp = await fetch(discovery.userInfoEndpoint, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        })

        const user = await resp.json()

        setAuthUser(service, user)
      }

      if (redirectId) {
        navigation.navigate(redirectId)
      }
    }

    if (response?.type === 'success') {
      const { code } = response.params

      handleResponse(code).catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e)
      })
    }
  }, [
    config,
    discovery,
    request,
    response,
    service,
    navigation,
    redirectId,
    setAuthToken,
    setAuthUser,
  ])

  useEffect(() => {
    if (request != null) {
      promptAsync().catch(() => null)
    }
  }, [request, promptAsync])

  return (
    <ApiResponseProvider page={tree?.main} {...apiMapper}>
      {tree?.main.nodes ? <RecursiveRenderer nodes={tree.main.nodes} /> : null}
    </ApiResponseProvider>
  )
}
