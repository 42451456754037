import React, { forwardRef } from 'react'
import { Text, View } from 'react-native'
import { Node } from '@rapid/core-types'
import { useInjectMouseHandlers } from '../util/injectMouseHandlers'
import { NodeRendererProps } from '../types'

const date = new Date().toLocaleDateString()

export const InputDateTimePickerWebEditor = forwardRef<any, NodeRendererProps<Node>>(
  ({ node, style }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <View style={style} ref={ref} {...editorProps}>
        <Text>{date}</Text>
      </View>
    )
  }
)
