import { Node } from '@rapid/core-types'
import { useApiRequestConfig, useApiResponse } from '../../shared/context/ApiResponse'
import { useFavorites } from '../../shared/context/Favorites'
import { useNavigationApiParams } from '../../shared/context/NavigationApiParams'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { createUnmappedValue, resolveApiResponseValue } from './resolveApiResponseValue'
import { resolveFavoriteValue } from './resolveFavoriteValue'
import { resolveStateMappedValue } from './resolveStateMappedValue'
import { nodeHasApiConfig } from './nodeHasApiConfig'
import { nodeHasFavoritesConfig } from './nodeHasFavoritesConfig'
import { nodeHasStateMapping } from './nodeHasStateMapping'

const { useAppState } = resolveAppStateContext()

export const useResolveNodeValue = (node: Node, iterationIndex: number | undefined) => {
  const apiResponseData = useApiResponse()
  const storedFavorites = useFavorites()
  const navigationApiParams = useNavigationApiParams()

  const { appState } = useAppState()

  const requestConfig = useApiRequestConfig()

  const resolvedValue = nodeHasApiConfig(node)
    ? resolveApiResponseValue(
        node,
        iterationIndex,
        apiResponseData,
        requestConfig,
        navigationApiParams
      )
    : nodeHasFavoritesConfig(node)
    ? resolveFavoriteValue(node, iterationIndex, storedFavorites)
    : nodeHasStateMapping(node)
    ? resolveStateMappedValue(node, appState)
    : createUnmappedValue(node)

  return resolvedValue
}
