/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react'
import { NodeTree } from '@rapid/core-types'
import { resolvePageDataContext } from '../../../shared/resolve/pageDataContext'
const { useFindNodeById } = resolvePageDataContext()

export const useChartData = (childNodes: NodeTree[]) => {
  if (childNodes === undefined) {
    return []
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatedNodes: any[] = []
  childNodes.forEach((childNode: NodeTree) => {
    const updatedNode = useFindNodeById(childNode.id)
    updatedNodes.push(updatedNode)
  })

  const data = useMemo(
    () =>
      updatedNodes.reduce<Array<{ x: string; y: number; color: any; interpolation: any }>>(
        (acc, dataPoint) => {
          const x = dataPoint?.value?.title
          const y = dataPoint?.value?.value
          const color = dataPoint?.style?.default.color

          const interpolation = dataPoint?.value

          acc.push({
            x,
            y,
            color,
            interpolation,
          })

          return acc
        },
        []
      ),
    [updatedNodes]
  )

  return data
}
