import { take } from 'ramda'
import { DataPath, PathList } from '@rapid/core-types'
import { isArrayValue, isEmptyArray } from '@rapid/api-mapper'
import { getPathConfig } from './getPathConfig'
import { ResolvedDataPath } from './types'

export const resolveApiResponsePath = (
  pathList: PathList | undefined,
  path: DataPath,
  iterationIndex?: number | undefined
) =>
  path.reduce<ResolvedDataPath>((acc, segment, segmentIndex) => {
    if (isEmptyArray(segment)) {
      // TODO Use Array splice instead
      const ancestorConfig = getPathConfig(pathList, take(segmentIndex, path))
      //   const ancestorConfig = getPathConfig(pathList, path.splice(0, segmentIndex))

      acc.push(
        ancestorConfig && isArrayValue(ancestorConfig) && ancestorConfig?.index != null
          ? ancestorConfig.index
          : iterationIndex ?? 0
      )
    } else {
      acc.push(segment)
    }

    return acc
  }, [])
