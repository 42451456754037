import React, { forwardRef } from 'react'
import { Platform } from 'react-native'
import { InputPickerNode } from '@rapid/node-type'
import { NodeRendererProps } from '../types'
import { InputPickerNative } from './InputPickerNative'
import { InputPickerWeb } from './InputPickerWeb'

export const InputPickerNodeRenderer = forwardRef<any, NodeRendererProps<InputPickerNode>>(
  (props, ref) => {
    if (Platform.OS === 'web') {
      return <InputPickerWeb ref={ref} {...props} />
    }

    return <InputPickerNative ref={ref} {...props} />
  }
)
