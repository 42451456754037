import React, { FC, useContext } from 'react'

const PageKeyContext = React.createContext<string | undefined>(undefined)

export const PageKeyProvider: FC<{ pageKey: string; children: React.ReactNode }> = ({
  pageKey,
  children,
}) => <PageKeyContext.Provider value={pageKey}>{children}</PageKeyContext.Provider>

export const usePageKey = () => {
  const key = useContext(PageKeyContext)

  if (key && key !== 'undefined') {
    return key
  }

  // throw new Error('PageKey context not available')
}
