import { Accelerometer } from 'expo-sensors'

// Shake sensitivity - lower this value to increase sensitivity
const THRESHOLD = 150

export const createShakeHandler = (cb: () => void) => {
  let lastX: number
  let lastY: number
  let lastZ: number

  let lastUpdate = 0

  Accelerometer.addListener((accelerometerData) => {
    const { x, y, z } = accelerometerData

    const timeNow = Date.now()

    if (timeNow - lastUpdate > 100) {
      const timeDiff = timeNow - lastUpdate

      lastUpdate = timeNow

      const speed = (Math.abs(x + y + z - lastX - lastY - lastZ) / timeDiff) * 10000

      if (speed > THRESHOLD) {
        cb()
      }

      lastX = x
      lastY = y
      lastZ = z
    }
  })

  return () => Accelerometer.removeAllListeners()
}
