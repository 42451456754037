import React, { FC, useCallback, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Message, NavigationChangePayload } from '@rapid/messenger'

export const NavigationListener: FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigation = useNavigation()

  const navigate = useCallback(
    (event: MessageEvent<NavigationChangePayload>) => {
      if (event.data.id === Message.NavigationChange) {
        navigation.navigate(event.data.payload)
      }
    },
    [navigation]
  )

  useEffect(() => {
    window.addEventListener('message', navigate, false)

    return () => window.removeEventListener('message', navigate)
  }, [navigate])

  return <>{children}</>
}
