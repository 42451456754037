import React from 'react'
import { isDevice, osBuildFingerprint, osName } from 'expo-device'
import { Request } from '@rapid/fetch'
import { SetSelectedApp } from '../../setAppId'
import { DataRequestProvider } from '../../shared/context/DataRequest'
import { env } from '../../env'
import { refactorLocalhostBaseUrl } from '../App/lib/hooks/util'
import { AppSelectorRealDevice } from './Device'
import { AppSelectorSimulator } from './Simulator'

const request = new Request({ baseUrl: refactorLocalhostBaseUrl(env.API_URL) })

export const AppSelector = ({ setSelectedApp }: { setSelectedApp: SetSelectedApp }) => {
  // Android emulators on M1 macs are flagged as real devices by expo device
  // https://github.com/expo/expo/issues/16165
  return !isDevice || (osName === 'Android' && osBuildFingerprint?.includes('emu')) ? (
    <DataRequestProvider request={request}>
      <AppSelectorSimulator setSelectedApp={setSelectedApp} />
    </DataRequestProvider>
  ) : (
    <AppSelectorRealDevice setSelectedApp={setSelectedApp} />
  )
}
