import { useContext } from 'react'
import { ApiResponseContext } from './ApiResponseProvider'

export const useApiResponseContext = () => {
  const ctx = useContext(ApiResponseContext)

  if (!ctx) {
    throw new Error('ApiResponseContext has not been mounted')
  }

  return ctx
}

export const useIsApiResponseLoading = () => {
  const ctx = useApiResponseContext()

  return ctx.isLoading
}

export const useApiResponse = () => {
  const ctx = useApiResponseContext()

  return ctx.apiResponseData
}

export const useApiRequestConfig = () => {
  const ctx = useApiResponseContext()

  return ctx.requestConfig
}
