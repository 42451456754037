import React, { forwardRef } from 'react'
import { Switch } from 'react-native'
import { InputSwitchNode } from '@rapid/node-type'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

export const InputSwitchNodeRenderer = forwardRef<any, NodeRendererProps<InputSwitchNode>>(
  ({ node, style }, ref) => {
    const onChange = useFormFieldChangeHandler(node)

    const value = useFormFieldValue(node)

    return (
      <Switch
        style={style}
        value={value}
        onValueChange={onChange}
        ref={ref}
        {...useInjectMouseHandlers(node)}
      />
    )
  }
)
