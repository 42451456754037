interface AddressValue {
  name: string
  lat: number
  lng: number
}

export const isAddressValue = (value: any): value is AddressValue =>
  typeof value?.name === 'string' &&
  typeof value?.lat === 'number' &&
  typeof value?.lng === 'number'
