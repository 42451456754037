import React, { useCallback, useEffect, useState } from 'react'
import { Alert, StyleSheet, Text, View } from 'react-native'
import { BarCodeScanner, BarCodeScannerResult } from 'expo-barcode-scanner'
import { SetSelectedApp } from '../../../setAppId'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

export const QRScanner = ({ setSelectedApp }: { setSelectedApp: SetSelectedApp }) => {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null)

  useEffect(() => {
    const requestPermission = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync()

      setHasPermission(status === 'granted')
    }

    requestPermission().catch(() => null)
  }, [])

  const handleBarCodeScanned = useCallback(
    ({ data }: BarCodeScannerResult) => {
      const [id, name] = data.split('|')

      try {
        setSelectedApp({ id, name }).catch(() => null)
      } catch (e) {
        Alert.alert('Sorry, something went wrong')
      }
    },
    [setSelectedApp]
  )

  if (hasPermission === null) {
    return <Text>Requesting camera permission</Text>
  }

  if (hasPermission === false) {
    return <Text>No access to camera</Text>
  }

  return (
    <View style={styles.container}>
      <BarCodeScanner
        onBarCodeScanned={handleBarCodeScanned}
        style={StyleSheet.absoluteFillObject}
      />
    </View>
  )
}
