import React, { forwardRef, useCallback, useRef } from 'react'
import { Animated } from 'react-native'
import { ImageNode } from '@rapid/node-type'
import { AppSafeStyleMap } from '@rapid/style-helpers'
import { useInjectMouseHandlers } from '../util/injectMouseHandlers'

export const AnimatedImage = forwardRef<
  any,
  { node: ImageNode; uri: string; style: Partial<AppSafeStyleMap> }
>(({ node, uri, style }, ref) => {
  const editorProps = useInjectMouseHandlers(node)

  const fadeAnim = useRef(new Animated.Value(0)).current

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: node.meta?.fadeIn.speed ?? 300,
      useNativeDriver: true,
    }).start()
  }, [fadeAnim, node])

  return (
    <Animated.Image
      source={{ uri }}
      ref={ref}
      style={[
        style,
        {
          opacity: fadeAnim,
        },
      ]}
      onLoadEnd={fadeIn}
      {...editorProps}
    />
  )
})
