import Constants from 'expo-constants'

if (Constants?.manifest?.extra?.editorMode == null) {
  throw new Error(`"editorMode" variable not specified`)
}

if (!Constants.manifest.extra.apiUrl) {
  throw new Error(`"apiUrl" variable not specified`)
}

if (!Constants.manifest.extra.socketUrl) {
  throw new Error(`"socketUrl" variable not specified`)
}

export const env: {
  IS_EDITOR_MODE: boolean
  API_URL: string
  SOCKET_URL: string
  APP_ID: string | undefined
} = {
  IS_EDITOR_MODE: Constants.manifest.extra.editorMode,
  API_URL: Constants.manifest.extra.apiUrl,
  SOCKET_URL: Constants.manifest.extra.socketUrl,
  APP_ID: Constants.manifest.extra.appId,
}
