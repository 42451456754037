import React from 'react'
import { Dimensions, Platform, Text, TouchableOpacity, View } from 'react-native'

export const QRButton = ({ openQRScanner }: { openQRScanner: () => void }) =>
  Platform.OS !== 'web' ? (
    <View
      style={{
        width: Dimensions.get('window').width,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
        flexGrow: 1,
      }}
    >
      <TouchableOpacity onPress={openQRScanner}>
        <View
          style={{
            width: 140,
            height: 140,
            borderColor: 'white',
            borderWidth: 3,
            borderRadius: 70,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 16, color: 'white' }}>Scan QR code</Text>
        </View>
      </TouchableOpacity>
    </View>
  ) : null
