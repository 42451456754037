import React, { FC, useContext, useEffect, useReducer } from 'react'
import { Node } from '@rapid/core-types'
import { isNode } from '@rapid/util'
import { Message, NavigationChangePayload, ResetPageDataPayload } from '@rapid/messenger'
import { usePageKey } from '../../../../../shared/context/PageKey'
import { PageData } from '../../../types'
import { PageDataMap } from './types'
import { pageDataReducer } from './reducer'

const PageDataContext = React.createContext<PageDataMap | undefined>(undefined)

export const PageDataProvider: FC<{ appId: string; data: PageData; children: React.ReactNode }> = ({
  children,
}) => {
  const [data, dispatch] = useReducer(pageDataReducer, {})

  useEffect(() => {
    window.addEventListener(
      'message',
      (event: MessageEvent<ResetPageDataPayload | NavigationChangePayload>) => {
        const { data: message } = event

        if (message.id === Message.NavigationChange) {
          dispatch({ type: 'empty' })
        }

        if (message.id === Message.ResetNodeMap) {
          const { pageId, nodeMap, nodeTree } = message.payload

          dispatch({ type: 'reset', pageId, nodeMap, nodeTree })
        }
      },
      false
    )
  }, [])

  return <PageDataContext.Provider value={data}>{children}</PageDataContext.Provider>
}

export const usePageDataContext = () => useContext(PageDataContext)

export const usePageNodeTree = () => {
  const pageMap = usePageDataContext()

  const pageKey = usePageKey()

  return pageMap?.[pageKey]?.nodeMap && pageMap[pageKey]?.nodeTree
    ? pageMap[pageKey]?.nodeTree
    : undefined
}

export const usePageNodeTreeForPageKey = (pageKey?: string) => {
  const pageMap = usePageDataContext()

  if (!pageKey) {
    return undefined
  }

  return pageMap?.[pageKey]?.nodeMap && pageMap[pageKey]?.nodeTree
    ? pageMap[pageKey]?.nodeTree
    : undefined
}

export const useRootNodeTree = () => {
  const pageMap = usePageDataContext()

  return pageMap?.root?.nodeTree
}

export const usePageNodeMap = () => {
  const pageMap = usePageDataContext()

  const pageKey = usePageKey()

  return pageMap?.[pageKey]?.nodeMap
}

export const useRootNodeMap = () => {
  const pageMap = usePageDataContext()

  return pageMap?.root?.nodeMap
}

export const useNode = (node: Node) => {
  const pageMap = usePageDataContext()

  const pageKey = usePageKey()

  const realNode = pageMap?.[pageKey]?.nodeMap?.[node.id]

  if (!isNode(realNode)) {
    throw new Error('No node in nodeMap context')
  }

  return realNode
}

export const useFindNodeById = (id: string) => {
  const pageMap = usePageDataContext()

  const pageKey = usePageKey()

  const realNode = pageMap?.[pageKey]?.nodeMap?.[id]

  if (!isNode(realNode)) {
    throw new Error('No node in nodeMap context')
  }

  return realNode
}
