import React from 'react'
import { ScrollView, Text, TouchableHighlight, View } from 'react-native'
import { App, SetSelectedApp } from '../../../setAppId'

export const AppList = ({
  appList,
  setSelectedApp,
}: {
  appList: App[]
  setSelectedApp: SetSelectedApp
}) => (
  <>
    <Text
      style={{
        paddingLeft: 20,
        marginBottom: 10,
        color: 'white',
      }}
    >
      Previously Scanned Apps:
    </Text>

    <ScrollView>
      {appList?.map((app) => (
        <TouchableHighlight
          key={app.id}
          onPress={() => setSelectedApp({ id: app.id, name: app.name })}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingVertical: 10,
              paddingHorizontal: 20,
              borderBottomColor: '#1e1e21',
              borderBottomWidth: 1,
            }}
          >
            <View
              style={{
                width: 60,
                height: 60,
                backgroundColor: 'black',
                borderRadius: 10,
                alignContent: 'center',
                justifyContent: 'center',
                marginRight: 20,
              }}
            >
              <Text
                style={{ color: 'white', textAlign: 'center', fontSize: 20, fontWeight: '900' }}
              >
                {app?.name?.slice(0, 1)}
              </Text>
            </View>

            <Text style={{ color: 'white' }}>{app.name}</Text>
          </View>
        </TouchableHighlight>
      ))}
    </ScrollView>
  </>
)
