import React, { forwardRef } from 'react'
import { VictoryChart, VictoryLegend, VictoryLine, VictoryTheme } from 'victory-native'
import { Node } from '@rapid/core-types'
import { NodeRendererProps } from './types'
import { useChartLineData } from './util/useChartLineData'

export const ChartLineGroupNodeRenderer = forwardRef<any, NodeRendererProps<Node>>(
  ({ childNodes }: NodeRendererProps<Node>) => {
    const legendArray: any[] = []
    let data: any[] = []

    return (
      <VictoryChart>
        {childNodes.map(function (childNode: any) {
          if (childNode?.nodes.length > 0) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            data = useChartLineData(childNode?.nodes)
          }

          legendArray.push({
            name: childNode.title,
            y: childNode.title,
            symbol: { fill: !data[0] ? 'black' : data[0].color },
          })
          if (data != null) {
            return (
              <VictoryLine
                data={data}
                style={{ data: { stroke: !data[0] ? 'black' : data[0].color } }}
                theme={VictoryTheme.material}
              />
            )
          }
        })}
        {legendArray != null ? (
          <VictoryLegend
            x={150}
            y={10}
            orientation="horizontal"
            gutter={20}
            style={{ border: { stroke: 'black' }, title: { fontSize: 20 } }}
            data={legendArray}
          />
        ) : null}
      </VictoryChart>
    )
  }
)
