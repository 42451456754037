import React, { useEffect, useMemo } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { BottomTabBarProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer'
import { sendPreviewFrameReadyMessage } from '@rapid/messenger'
import { NavigationName, PageName } from '@rapid/bible'
import { defaultScreens } from '../../shared/defaultScreens'
import { NavigationDrawer, NavigationTabBar } from './Navigation'
import { usePageList } from './lib/context/PageList'
import { EmptyPage } from './Page/EmptyPage'
import { PageTypeRenderer } from './Page'

export const Navigator = ({ navigationName }: { navigationName: NavigationName | PageName }) => {
  useEffect(() => {
    sendPreviewFrameReadyMessage()
  }, [])

  const links = usePageList()

  const [Navigation, navigationProps] = useMemo(() => {
    switch (navigationName) {
      case NavigationName.Tab:
        return [
          createBottomTabNavigator(),
          { tabBar: (props: BottomTabBarProps) => <NavigationTabBar {...props} /> },
        ]
      case NavigationName.Drawer:
        return [
          createDrawerNavigator(),
          {
            gestureEnabled: false,
            drawerContent: (props: DrawerContentComponentProps) => <NavigationDrawer {...props} />,
          },
        ]
      default:
        throw new Error('Unknown root page type')
    }
  }, [navigationName])

  return (
    <NavigationContainer>
      <Navigation.Navigator screenOptions={{ headerShown: false }} {...navigationProps}>
        <Navigation.Screen name="placeholder" component={EmptyPage} />

        {defaultScreens.map(([name, Component]) => (
          <Navigation.Screen key={name} name={name} component={Component} />
        ))}

        {links.map((link) => (
          <Navigation.Screen
            key={link.id}
            name={link.id}
            component={PageTypeRenderer}
            options={{ title: link.name }}
          />
        ))}
      </Navigation.Navigator>
    </NavigationContainer>
  )
}
