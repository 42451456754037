import React, { forwardRef, useCallback, useRef } from 'react'
import { Node } from '@rapid/core-types'
import { resolveAppStateContext } from '../../../shared/resolve/appStateContext'
import { NodeRendererProps } from '../types'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

export const InputDateTimePickerWeb = forwardRef<any, NodeRendererProps<Node>>(
  ({ node, style }, _ref) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const formFieldChangeHandler = useFormFieldChangeHandler(node)

    const value = useFormFieldValue(node)

    const onChange = useCallback(() => {
      formFieldChangeHandler(inputRef.current?.valueAsDate)
    }, [inputRef, formFieldChangeHandler])

    return (
      <input
        type="date"
        onChange={onChange}
        style={{ style }}
        defaultValue={value?.slice(0, 10)}
        ref={inputRef}
      />
    )
  }
)
