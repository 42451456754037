import { path } from 'ramda'
import { format, parseJSON } from 'date-fns'
import { Node, Request } from '@rapid/core-types'
import { isArrayValue } from '@rapid/api-mapper'
import { NavigationApiParams } from '../../types'
import { getPathConfig } from './getPathConfig'
import { resolveApiResponsePath } from './resolveApiResponsePath'
import { NodeWithApiConfig, ResolvedDataValue } from './types'

const optionallyFormatValue = (node: NodeWithApiConfig, value: unknown) => {
  if (node.api.parseAs === 'date' && typeof value === 'string' && node.api.format?.length > 0) {
    const parsedDate = parseJSON(value)

    return format(parsedDate, node.api.format)
  }

  return value
}

export const resolveApiResponseValue = (
  node: NodeWithApiConfig,
  iterationIndex: number | undefined,
  apiResponseData: unknown,
  requestConfig: Request | undefined,
  navigationApiParams: NavigationApiParams | undefined
): ResolvedDataValue => {
  const pathConfig = getPathConfig(requestConfig?.paths, node.api.path)
  let apiIndex = iterationIndex

  if (navigationApiParams?.dataPath && apiResponseData) {
    const apiDataPath = resolveApiResponsePath(requestConfig?.paths, navigationApiParams?.dataPath)
    if (Array.isArray(apiDataPath)) {
      const apiDataField = apiDataPath.pop()
      // get rid of the index
      apiDataPath.pop()

      if (apiDataField) {
        apiIndex = path(apiDataPath, apiResponseData).findIndex(
          (item: any) => item[apiDataField] === navigationApiParams.dataValue
        )
      }
    }
  }

  const resolvedPath = resolveApiResponsePath(requestConfig?.paths, node.api.path, apiIndex)

  if (pathConfig && isArrayValue(pathConfig) && !pathConfig.index) {
    const resolvedValue = path(resolvedPath, apiResponseData)

    return {
      isArray: true,
      path: resolvedPath,
      length: Array.isArray(resolvedValue) ? resolvedValue.length : 0,
    }
  }

  return {
    isArray: false,
    path: resolvedPath,
    value: optionallyFormatValue(node, path(resolvedPath, apiResponseData)),
  }
}

export const createUnmappedValue = (
  node: Node
): {
  isArray: false
  value: any
} => ({
  isArray: false,
  value: node.value,
})
