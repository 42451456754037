import React from 'react'
import { NodeTree } from '@rapid/core-types'
import { NodeRenderer } from './NodeRenderer'

export const RecursiveRenderer = ({ nodes }: { nodes: NodeTree[] }) => {
  if (!nodes.length) {
    return null
  }

  return (
    <>
      {nodes
        .filter((node) => !!node && !!node.active)
        .map((node) => (
          <NodeRenderer node={node} key={node.id}>
            <RecursiveRenderer nodes={node.nodes} />
          </NodeRenderer>
        ))}
    </>
  )
}
