import * as Font from 'expo-font'
import { FontFile } from '@rapid/core-types'
import { resolveAssetPath } from '@rapid/util'

export const loadFonts = async (fontList: FontFile[], baseUrl: string) => {
  const fontMap = fontList.reduce<Record<string, string>>((acc, font) => {
    const fontPath = resolveAssetPath(baseUrl, font.id, font.mimeType)

    if (fontPath) {
      acc[font.meta.fontName] = fontPath
    }

    return acc
  }, {})

  await Font.loadAsync(fontMap)
}
