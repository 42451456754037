import React, { useCallback } from 'react'
import { Image, ScrollView, Text, TouchableHighlight, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { PageTreeByViewKey } from '@rapid/core-types'
import { isFileRef } from '@rapid/util'
import { useFavorites } from '../../shared/context/Favorites'
import { useResolveAssetPath } from '../../shared/context/AssetPath'

const iconReplacement = (title: string) => (
  <View
    style={{
      width: 60,
      height: 60,
      backgroundColor: 'black',
      borderRadius: 10,
      alignContent: 'center',
      justifyContent: 'center',
      marginRight: 10,
    }}
  >
    <Text style={{ color: 'white', textAlign: 'center', fontSize: 20, fontWeight: '900' }}>
      {title.slice(0, 1)}
    </Text>
  </View>
)

export const Favorites = ({ tree }: { tree: PageTreeByViewKey }) => {
  const storedFavorites = useFavorites()

  const navigation = useNavigation()

  const navigate = useCallback(
    (link: string) => {
      navigation.navigate(link)
    },
    [navigation]
  )

  const resolveAssetPath = useResolveAssetPath()

  return (
    <>
      <Text
        style={{
          paddingLeft: 20,
          marginTop: 20,
          marginBottom: 10,
          color: 'black',
        }}
      >
        {tree.main.title}
      </Text>

      <ScrollView>
        {storedFavorites?.map(({ id, title, description, value }) => (
          <TouchableHighlight key={id} onPress={() => navigate(id)}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 10,
                paddingHorizontal: 20,
                borderBottomColor: '#1e1e21',
                borderBottomWidth: 1,
              }}
            >
              {value && isFileRef(value) ? (
                <Image
                  style={{
                    width: 60,
                    height: 60,
                    marginRight: 10,
                  }}
                  source={{ uri: resolveAssetPath(value.id, value.mimeType) }}
                />
              ) : (
                iconReplacement(title)
              )}

              <View
                style={{
                  justifyContent: 'space-evenly',
                }}
              >
                <Text style={{ color: 'black', fontSize: 16 }}>{title}</Text>
                {description && <Text style={{ color: 'black' }}>{description}</Text>}
              </View>
            </View>
          </TouchableHighlight>
        ))}
      </ScrollView>
    </>
  )
}
