import React from 'react'
import { Request } from '@rapid/fetch'
import { AppStateProvider } from '@rapid/app-state'
import { SharedAppProvider } from '../../shared/components/SharedAppProvider'
import { env } from '../../env'
import { PageDataProvider } from './lib/context/PageData'
import { PageListProvider } from './lib/context/PageList'
import { useLoadAppData } from './lib/hooks/useLoadAppData'
import { Navigator } from './Navigator'
import { refactorLocalhostBaseUrl } from './lib/hooks/util'

const request = new Request({ baseUrl: refactorLocalhostBaseUrl(env.API_URL) })

export const App = ({ appId }: { appId: string }) => {
  const [isLoading, appData] = useLoadAppData(appId, request, refactorLocalhostBaseUrl(env.API_URL))

  if (isLoading || !appData) {
    return null
  }

  const { pageData, pageList, rootPage, ...rest } = appData

  return (
    <SharedAppProvider {...rest}>
      <PageDataProvider appId={appId} data={pageData}>
        <PageListProvider pageList={pageList}>
          <AppStateProvider>
            <Navigator navigationName={rootPage.name} />
          </AppStateProvider>
        </PageListProvider>
      </PageDataProvider>
    </SharedAppProvider>
  )
}
