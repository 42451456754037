/* eslint-disable @typescript-eslint/unbound-method */
import React, { FC, useContext } from 'react'
import { NavigationHelpers } from '@react-navigation/native'

const MenuNavigationContext = React.createContext<NavigationHelpers<any> | undefined>(undefined)

export const MenuNavigationProvider: FC<{
  navigation: NavigationHelpers<any>
  children: React.ReactNode
}> = ({ navigation, children }) => {
  return (
    <MenuNavigationContext.Provider value={navigation}>{children}</MenuNavigationContext.Provider>
  )
}

export const useMenuNavigate = () => {
  const ctx = useContext(MenuNavigationContext)

  if (!ctx) {
    throw new Error('MenuNavigationContext not in tree')
  }

  return ctx
}
