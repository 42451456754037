import React, { FC, useContext } from 'react'
import { Header } from '@rapid/core-types'

const HeaderContext = React.createContext<Header | null>(null)

export const HeaderProvider: FC<{
  header: Header | null
  children: React.ReactNode
}> = ({ header, children }) => (
  <HeaderContext.Provider value={header}>{children}</HeaderContext.Provider>
)

export const useHeader = () => {
  const ctx = useContext(HeaderContext)

  return ctx
}
