/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { Request } from '@rapid/fetch'
import { EditorAppDataResponseData, EditorAppDataResponseError } from '@rapid/request-types'
import { AppData } from '../../types'
import { loadFonts } from '../../../../shared/loadFonts'

export const useLoadAppData = (
  appId: string,
  dataRequest: Request,
  baseUrl: string
): [true, undefined] | [false, AppData] => {
  const [isLoading, setIsLoading] = useState(true)

  const [appData, setAppData] = useState<AppData>()

  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      const resp = await dataRequest.get<EditorAppDataResponseData, EditorAppDataResponseError>(
        `/app/data/editor/${appId}`
      )

      if (resp.ok) {
        try {
          setAppData({
            pageData: {},
            rootPage: resp.data.rootPage,
            pageList: resp.data.pageList,
            breakpointList: resp.data.breakpointList,
            servicesById: resp.data.servicesById,
            requestsById: resp.data.requestsById,
            fontList: resp.data.fontList,
            header: resp.data.header,
          })

          await loadFonts(resp.data.fontList, baseUrl)
        } catch (e) {
          // We might want to provide this error information to an error reporting service
          console.warn(e)
        } finally {
          setIsLoading(false)
        }
      }
    }

    loadResourcesAndDataAsync().catch(() => null)
  }, [appId, dataRequest, baseUrl])

  return !isLoading && appData ? [false, appData] : [true, undefined]
}
