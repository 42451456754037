import React from 'react'
import { RouteProp } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { PageTreeByViewKeyWithLinks } from '@rapid/core-types'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { RouteParams } from '../../../types'
import { RootRenderer } from '../../../renderer/RootRenderer'
import { PageTypeRenderer } from './index'

const Tab = createMaterialTopTabNavigator()

const TabBar = ({ route }: { route: RouteProp<RouteParams, 'Default'> }) => (
  <PageKeyProvider pageKey={route.name}>
    <RootRenderer />
  </PageKeyProvider>
)

export const TabPage = ({
  route,
  tree,
}: {
  route: RouteProp<RouteParams, 'Default'>
  tree: PageTreeByViewKeyWithLinks
}) => {
  return (
    <Tab.Navigator tabBar={() => <TabBar route={route} />}>
      {tree.links.map((link) => (
        <Tab.Screen key={link.id} name={link.id} component={PageTypeRenderer} />
      ))}
    </Tab.Navigator>
  )
}
