export const rgbToHex = (rgbColor?: string): string | undefined => {
  if (!rgbColor || !rgbColor.startsWith('rgb(')) {
    return undefined
  }

  const rgbValues = rgbColor.substring(4, rgbColor.length - 1).split(',')
  if (rgbValues.length !== 3) {
    return undefined
  }

  const [r, g, b] = rgbValues.map((value) => parseInt(value.trim(), 10))
  if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) {
    return undefined
  }

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}
