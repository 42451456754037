import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import { Animated, Dimensions, Image, View } from 'react-native'
import { pick, without } from 'ramda'
import { ImageNode } from '@rapid/node-type'
import { AppSafeStyleMap } from '@rapid/style-helpers'
import { definition } from '@rapid/bible'
import { useScrollOffset } from '../ScrollView/context/ScrollViewOffset'
import { useInjectMouseHandlers } from '../util/injectMouseHandlers'

const WINDOW_HEIGHT = Dimensions.get('window').height

const containerStyleKeys = without(['tintColor', 'resizeMode'], definition.node.Image.styles)

const imageStyleKeys = ['width', 'height', 'tintColor', 'resizeMode']

export const ParallaxImage = forwardRef<
  Image,
  { node: ImageNode; uri: string; style: Partial<AppSafeStyleMap> }
>(({ node, uri, style }, ref) => {
  const [measurement, setMeasurement] = useState({ width: 0, height: 0, pageY: 0 })

  const editorProps = useInjectMouseHandlers(node)

  const { scrollY } = useScrollOffset()

  const fadeAnim = useRef(new Animated.Value(node.meta?.fadeIn.enabled ? 0 : 1)).current

  const fadeIn = useCallback(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: node.meta?.fadeIn.speed ?? 300,
      useNativeDriver: true,
    }).start()
  }, [fadeAnim, node])

  const handleOnLayout = useCallback(() => {
    if (typeof ref !== 'function') {
      ref?.current?.measure((_x, _y, width, height, _pageX, pageY) =>
        setMeasurement({ width, height, pageY })
      )
    }
  }, [ref])

  const containerStyle = useMemo(
    () => ({ ...pick(containerStyleKeys, style), overflow: 'hidden', position: 'relative' }),
    [style]
  )

  const imageStyle = useMemo(
    () => ({
      ...pick(imageStyleKeys, style),
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: fadeAnim,
    }),
    [style, fadeAnim]
  )

  const parallaxStyle = useMemo(() => {
    const parallaxPadding = measurement.height * (node.meta?.parallax?.factor ?? 0.2)

    const transform = scrollY
      ? [
          {
            translateY: scrollY.interpolate({
              inputRange: [
                measurement.pageY - measurement.height,
                measurement.pageY + measurement.height + WINDOW_HEIGHT,
              ],
              outputRange: [-parallaxPadding, parallaxPadding],
              extrapolate: 'clamp',
            }),
          },
        ]
      : [{ translateY: -parallaxPadding }]

    return {
      height: measurement.height + parallaxPadding * 2,
      width: measurement.width,
      transform,
    }
  }, [node, measurement, scrollY])

  return (
    <View style={containerStyle} ref={ref} onLayout={handleOnLayout} {...editorProps}>
      <Animated.Image source={{ uri }} style={[imageStyle, parallaxStyle]} onLoadEnd={fadeIn} />
    </View>
  )
})
