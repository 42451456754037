import React, { forwardRef } from 'react'
import { Platform } from 'react-native'
import { WebView } from 'react-native-webview'
import { WebViewNode } from '@rapid/node-type'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const WebViewNodeRenderer = forwardRef<any, NodeRendererProps<WebViewNode>>(
  ({ node, value, style, children }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    if (Platform.OS === 'web') {
      return (
        <iframe
          title="WebView"
          width={style.width}
          height={style.height}
          src={value}
          style={{
            border: 0,
            ...style,
          }}
          {...editorProps}
        />
      )
    }

    return (
      <WebView source={{ uri: value }} containerStyle={style} ref={ref} {...editorProps}>
        {children}
      </WebView>
    )
  }
)
