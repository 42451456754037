import { Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AsyncStorageKey } from '@rapid/app-state'

export const addToFavorite = async (pageId: string | undefined) => {
  try {
    const storedFavorites = await AsyncStorage.getItem(AsyncStorageKey.Favorites)
    const favorites = storedFavorites && JSON.parse(storedFavorites)

    if (pageId) {
      let updatedFavorites = [pageId]

      if (favorites && Array.isArray(favorites)) {
        updatedFavorites = favorites.includes(pageId) ? favorites : favorites.concat(pageId)
      }

      await AsyncStorage.setItem(AsyncStorageKey.Favorites, JSON.stringify(updatedFavorites))
      Alert.alert('Added to favorites')
    } else {
      Alert.alert('Sorry, something went wrong')
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}
