/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { Alert } from 'react-native'
import { Request } from '@rapid/fetch'
import { NativeAppDataResponseData, NativeAppDataResponseError } from '@rapid/request-types'
import { env } from '../../../../env'
import { loadFonts } from '../../../../shared/loadFonts'
import { AppData } from '../../types'
import { createPageList } from './util'

export const useLoadAppData = (
  appId: string,
  dataRequest: Request,
  baseUrl: string
): [true, undefined] | [false, AppData] => {
  const [isLoading, setIsLoading] = useState(true)

  const [appData, setAppData] = useState<AppData>()

  useEffect(() => {
    const loadResourcesAndDataAsync = async () => {
      const useVersionedData = env.APP_ID != null

      const resp = await dataRequest.get<NativeAppDataResponseData, NativeAppDataResponseError>(
        `/app/data/native/${appId}?useVersionedData=${useVersionedData.toString()}`
      )

      if (resp.ok) {
        try {
          const pageList = createPageList(resp.data.pageMap)

          setAppData({
            rootPage: resp.data.rootPage,
            pageData: resp.data.pageMap,
            pageList,
            breakpointList: resp.data.breakpointList,
            servicesById: resp.data.servicesById,
            requestsById: resp.data.requestsById,
            fontList: resp.data.fontList,
            header: resp.data.header,
          })

          await loadFonts(resp.data.fontList, baseUrl)
        } catch (e) {
          // We might want to provide this error information to an error reporting service
          console.warn(e)
        } finally {
          setIsLoading(false)
        }
      } else {
        Alert.alert('App not found')

        console.log('Env APP_ID:', env.APP_ID)
        console.log('Base API_URL:', baseUrl)
        console.log(
          'Requested Url:',
          `${baseUrl}/app/data/native/${appId}?useVersionedData=${useVersionedData.toString()}`
        )
      }
    }

    loadResourcesAndDataAsync().catch(() => null)
  }, [appId, dataRequest, baseUrl])

  return !isLoading && appData ? [false, appData] : [true, undefined]
}
