import { PageTreeByViewKeyWithLinks } from '@rapid/core-types'
import { PageData } from './context'

export const pageDataReducer = (
  pageMap: PageData,
  action: {
    type: 'refresh'
    pageTree: PageTreeByViewKeyWithLinks
  }
) => {
  if (action.type === 'refresh') {
    return { ...pageMap, [action.pageTree.main.id]: action.pageTree }
  }

  throw new Error('Unknown action')
}
