import React from 'react'
import { RouteProp } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { PageTree } from '@rapid/core-types'
import { NavigationListener } from '../lib/context/NavigationListener'
import { SelectionProvider } from '../lib/context/Selection'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { RouteParams } from '../../../types'
import { RootRenderer } from '../../../renderer/RootRenderer'
import { useNavigationHeaderConfig } from '../../../shared/hooks/useNavigationHeaderConfig'

const Stack = createStackNavigator()

const PageProviders = ({ route }: { route: RouteProp<RouteParams, 'Default'> }) => (
  <PageKeyProvider pageKey={route.name}>
    <SelectionProvider>
      <RootRenderer />
    </SelectionProvider>
  </PageKeyProvider>
)

export const DefaultPage = ({
  route,
  tree,
}: {
  route: RouteProp<RouteParams, 'Default'>
  tree: PageTree
}) => {
  const navigationHeaderConfig = useNavigationHeaderConfig()

  return (
    <NavigationListener>
      {tree ? (
        <Stack.Navigator initialRouteName={route.name} screenOptions={navigationHeaderConfig}>
          <Stack.Screen name={tree.id} component={PageProviders} options={{ title: tree.title }} />
        </Stack.Navigator>
      ) : null}
    </NavigationListener>
  )
}
