import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Node } from '@rapid/core-types'
import { Message, NodeSelectionPayload } from '@rapid/messenger'
import { isNode } from '@rapid/util'
import { usePageNodeMap } from '../PageData'

interface Selection {
  id: string
  parents?: string[]
}

const compareNodeIds = (nodeA: Selection, nodeB: Node): boolean => nodeA.id === nodeB.id

const SelectionContext = React.createContext<Node | undefined>(undefined)

export const SelectionProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selection, setSelection] = useState<Selection | undefined>()

  const nodeMap = usePageNodeMap()

  const node = useMemo(() => {
    const realNode = selection?.id && nodeMap ? nodeMap[selection?.id] : undefined

    if (isNode(realNode)) {
      return realNode
    }
  }, [selection, nodeMap])

  const onNodeSelectionMessage = useCallback((event: MessageEvent<NodeSelectionPayload>) => {
    if (event.data.id === Message.NodeSelection) {
      setSelection(event.data.payload)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', onNodeSelectionMessage, false)

    return () => window.removeEventListener('message', onNodeSelectionMessage)
  }, [onNodeSelectionMessage])

  return <SelectionContext.Provider value={node}>{children}</SelectionContext.Provider>
}

export const useIsNodeSelected = (node?: Node) => {
  const selectedNode = useContext(SelectionContext)

  if (!selectedNode || !node) {
    return false
  }

  return compareNodeIds(selectedNode, node)
}

export const useSelectedNode = () => {
  const selectedNode = useContext(SelectionContext)

  return selectedNode
}
