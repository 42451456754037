import { Dispatch, useCallback } from 'react'
import { TokenResponse } from 'expo-auth-session'
import { Node, Service } from '@rapid/core-types'
import { Action, AppState, AuthData, initialState } from '@rapid/app-state'

export const useAppState = (): { appState: AppState; dispatch: Dispatch<Action> } => ({
  appState: initialState,
  dispatch: (_: Action) => undefined,
})

export const useFormFieldChangeHandler = (_: Node) => () => {}

export const useFormFieldValue = (_: Node) => undefined

export const useAuthState = () => {
  const setAuthToken = useCallback((_c: Service, _t: TokenResponse): void => {}, [])

  const setAuthUser = useCallback((_c: Service, _u: any) => {}, [])

  const getAuth = useCallback((_c: Service): AuthData | undefined => undefined, [])

  return { getAuth, setAuthToken, setAuthUser }
}
