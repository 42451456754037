import React, { FC, useContext, useMemo } from 'react'
import { FontFile } from '@rapid/core-types'
import { createStyleTransformationFunc } from '@rapid/style-helpers'

const StyleTransformationContext = React.createContext<
  | {
      styleTransformation: ReturnType<typeof createStyleTransformationFunc>
      fontMap: Record<string, string>
    }
  | undefined
>(undefined)

export const StyleTransformationProvider: FC<{
  fontList: FontFile[]
  children: React.ReactNode
}> = ({ fontList, children }) => {
  const fontMap = fontList.reduce<Record<string, string>>((acc, font) => {
    acc[font.id] = font.meta.fontName

    return acc
  }, {})

  const styleTransformation = useMemo(() => createStyleTransformationFunc({ fontMap }), [fontMap])

  return (
    <StyleTransformationContext.Provider
      value={{
        styleTransformation,
        fontMap,
      }}
    >
      {children}
    </StyleTransformationContext.Provider>
  )
}

export const useStyleTransformation = () => {
  const ctx = useContext(StyleTransformationContext)

  if (!ctx) {
    throw new Error('StyleTransformationProvider not in tree')
  }

  return ctx.styleTransformation
}

export const useStyleFontMap = () => {
  const ctx = useContext(StyleTransformationContext)

  if (!ctx) {
    throw new Error('StyleTransformationProvider not in tree')
  }

  return ctx.fontMap
}
