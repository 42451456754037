import React from 'react'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { MenuNavigationProvider } from '../../../shared/context/MenuNavigation'
import { RootRenderer } from '../../../renderer/RootRenderer'

export const NavigationDrawer = ({ navigation }: DrawerContentComponentProps) => (
  <PageKeyProvider pageKey="root">
    <MenuNavigationProvider navigation={navigation}>
      <RootRenderer />
    </MenuNavigationProvider>
  </PageKeyProvider>
)
