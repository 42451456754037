import React, { forwardRef } from 'react'
import { View } from 'react-native'
import { Node } from '@rapid/core-types'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const DefaultNodeRenderer = forwardRef<any, NodeRendererProps<Node>>(
  ({ node, style, children }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <View style={style} ref={ref} {...editorProps}>
        {children}
      </View>
    )
  }
)
