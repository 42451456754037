import React, { forwardRef } from 'react'
import { Text } from 'react-native'
import { TextNode } from '@rapid/node-type'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const TextNodeRenderer = forwardRef<Text, NodeRendererProps<TextNode>>(
  ({ node, value, style }, ref) => (
    <Text style={style} ref={ref} {...useInjectMouseHandlers(node)}>
      {value}
    </Text>
  )
)
