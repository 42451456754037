import React, { forwardRef } from 'react'
import { Platform } from 'react-native'
import { InputDateTimePickerNode } from '@rapid/node-type'
import { isWebEditorMode } from '../../../shared/platform'
import { NodeRendererProps } from '../types'
import { InputDateTimePickerAndroid } from './InputDateTimePickerAndroid'
import { InputDateTimePickerIOS } from './InputDateTimePickerIOS'
import { InputDateTimePickerWebEditor } from './InputDateTimePickerWebEditor'
import { InputDateTimePickerWeb } from './InputDateTimePickerWeb'

export const InputDateTimePickerNodeRenderer = forwardRef<
  any,
  NodeRendererProps<InputDateTimePickerNode>
>((props, ref) => {
  if (Platform.OS === 'ios') {
    return <InputDateTimePickerIOS ref={ref} {...props} />
  }

  if (Platform.OS === 'android') {
    return <InputDateTimePickerAndroid ref={ref} {...props} />
  }

  if (isWebEditorMode()) {
    return <InputDateTimePickerWebEditor ref={ref} {...props} />
  }

  return <InputDateTimePickerWeb ref={ref} {...props} />
})
