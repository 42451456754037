import React, { FC, useContext, useEffect, useMemo, useReducer } from 'react'
import { Manager } from 'socket.io-client'
import { Node, PageTreeByViewKeyWithLinks } from '@rapid/core-types'
import { findNestedNodeById } from '@rapid/util'
import { WSEvent } from '@rapid/bible'
import { usePageKey } from '../../../../../shared/context/PageKey'
import { env } from '../../../../../env'
import { pageDataReducer } from './reducer'

export type PageData = Record<string, PageTreeByViewKeyWithLinks>

const PageDataContext = React.createContext<PageData | undefined>(undefined)

export const PageDataProvider: FC<{ appId: string; data: PageData; children: React.ReactNode }> = ({
  appId,
  data,
  children,
}) => {
  const [pageMap, dispatch] = useReducer(pageDataReducer, data)

  useEffect(() => {
    const manager = new Manager(env.SOCKET_URL, { transports: ['websocket'] })

    const socket = manager.socket('/')

    socket.on('connect', () => {
      // eslint-disable-next-line no-console
      console.log(`Connected to ${env.SOCKET_URL}`)

      socket.emit(WSEvent.RequestChangeFeed, appId)

      socket.on(WSEvent.ChangeFeedReady, function (id: string) {
        // eslint-disable-next-line no-console
        console.log(`Changefeed ready for app with id: ${id}`)
      })

      socket.on(WSEvent.AppPageRefresh, function (pageTree: PageTreeByViewKeyWithLinks) {
        dispatch({ type: 'refresh', pageTree })
      })
    })

    manager.on('error', () => {
      // eslint-disable-next-line no-console
      console.log('Could not establish socket connection')
    })
  }, [appId])

  return <PageDataContext.Provider value={pageMap}>{children}</PageDataContext.Provider>
}

export const usePageDataContext = () => {
  const ctx = useContext(PageDataContext)

  if (!ctx) {
    throw new Error('Context not in tree')
  }

  return ctx
}

export const usePageNodeTree = () => {
  const ctx = usePageDataContext()

  const pageKey = usePageKey()

  return ctx[pageKey]
}

export const usePageNodeTreeForPageKey = (pageKey?: string) => {
  const ctx = usePageDataContext()

  if (!pageKey) {
    return undefined
  }

  return ctx[pageKey]
}

export const useRootNodeTree = () => {
  const ctx = usePageDataContext()

  return ctx.root
}

export const usePageNodeMap = () => ({})

export const useRootNodeMap = () => ({})

export const useNode = (node: Node) => node

export const useFindNodeById = (id: string) => {
  const ctx = usePageDataContext()

  const pageKey = usePageKey()

  const pageTree = ctx[pageKey]

  const node = useMemo(() => findNestedNodeById(pageTree, id), [pageTree, id])

  if (!node) {
    throw new Error(`Node with id ${id} not in node tree`)
  }

  return node
}
