import React, { FC, useContext, useRef } from 'react'
import { times } from 'ramda'
import { NodeTree } from '@rapid/core-types'
import { resolvePageDataContext } from '../shared/resolve/pageDataContext'
import { useStyleTransformation } from '../shared/context/StyleTransformation'
import { useResponsiveStyleKey } from '../shared/hooks/useResponsiveStyleKey'
import { isWebEditorMode } from '../shared/platform'
import { useResolveNodeValue } from './util/useResolveNodeValue'
import { Highlight } from './Highlight/Highlight'
import { getNode } from './NodeType'

const { useNode } = resolvePageDataContext()

const ArrayIndexContext = React.createContext<number | undefined>(undefined)

const ArrayIndexProvider: FC<{ index: number; children: React.ReactNode }> = ({
  index,
  children,
}) => {
  return <ArrayIndexContext.Provider value={index}>{children}</ArrayIndexContext.Provider>
}

export const NodeRenderer: FC<{ node: NodeTree; children: React.ReactNode }> = ({
  node,
  children,
}) => {
  const ref = useRef<any>()

  const realNode = useNode(node)

  if (realNode.api?.path && typeof realNode.api.path === 'string') {
    realNode.api.path = JSON.parse(realNode.api.path)
  }

  const responsiveStyleKey = useResponsiveStyleKey()

  const styleTransformation = useStyleTransformation()

  const style = styleTransformation(realNode, responsiveStyleKey)

  const Component = getNode(realNode.name)

  const iterationIndex = useContext(ArrayIndexContext)

  const resolvedData = useResolveNodeValue(realNode, iterationIndex)

  if (!Component) {
    return null
  }

  if (resolvedData?.isArray === true) {
    return (
      <>
        {times((index) => {
          const iterationRef = React.createRef<any>()

          return (
            <ArrayIndexProvider key={`${realNode.id}-${index}`} index={index}>
              {isWebEditorMode() ? <Highlight node={realNode} componentRef={iterationRef} /> : null}

              <Component
                node={realNode}
                childNodes={node.nodes}
                value={null}
                style={style}
                ref={iterationRef}
              >
                {children}
              </Component>
            </ArrayIndexProvider>
          )
        }, resolvedData.length)}
      </>
    )
  }

  return (
    <>
      {isWebEditorMode() ? <Highlight node={realNode} componentRef={ref} /> : null}

      <Component
        node={realNode}
        childNodes={node.nodes}
        value={resolvedData.value}
        style={style}
        ref={ref}
      >
        {children}
      </Component>
    </>
  )
}
