import React from 'react'
import { ActivityIndicator, Text, View } from 'react-native'

export const Loader = () => (
  <View
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#2f2f33',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <ActivityIndicator />

    <Text style={{ marginTop: 10, color: '#aaaaaa' }}>Loading Apps…</Text>
  </View>
)
