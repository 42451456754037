import React from 'react'
import { Request } from '@rapid/fetch'
import { SharedAppProvider } from '../../shared/components/SharedAppProvider'
import { env } from '../../env'
import { PageDataProvider } from './lib/context/PageData'
import { PageListProvider } from './lib/context/PageList'
import { useLoadAppData } from './lib/hooks/useLoadAppData'
import { Navigator } from './Navigator'

const request = new Request({ baseUrl: env.API_URL })

export const WebEditorApp = ({ appId }: { appId: string }) => {
  const [isLoading, appData] = useLoadAppData(appId, request, env.API_URL)

  if (isLoading || !appData) {
    return null
  }

  const { pageData, pageList, rootPage, ...rest } = appData

  return (
    <SharedAppProvider {...rest}>
      <PageDataProvider appId={appId} data={pageData}>
        <PageListProvider pageList={pageList}>
          <Navigator navigationName={rootPage.name} />
        </PageListProvider>
      </PageDataProvider>
    </SharedAppProvider>
  )
}
