import React, { forwardRef, useCallback } from 'react'
import { Alert, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { LinkNode } from '@rapid/node-type'
import { isWebEditorMode } from '../../shared/platform'
import { useMenuNavigate } from '../../shared/context/MenuNavigation'
import { usePageKey } from '../../shared/context/PageKey'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const LinkComponent = forwardRef<
  TouchableOpacity,
  NodeRendererProps<LinkNode> & { navigate: () => void }
>(({ node, style, children, navigate }, ref) => {
  const editorProps = useInjectMouseHandlers(node)

  if (isWebEditorMode()) {
    return (
      <View style={style} ref={ref} {...editorProps}>
        {children}
      </View>
    )
  }

  return (
    <TouchableOpacity style={style} ref={ref} onPress={navigate} {...editorProps}>
      {children}
    </TouchableOpacity>
  )
})

const MenuLink = forwardRef<TouchableOpacity, NodeRendererProps<LinkNode>>((props, ref) => {
  const navigation = useMenuNavigate()

  const { node } = props

  const navigate = useCallback(() => {
    const link = node.value?.link

    if (!link) {
      Alert.alert(`Cannot open link 'undefined'`)

      return
    }

    navigation.reset({
      index: 0,
      routes: [
        {
          name: link,
        },
      ],
    })
  }, [node, navigation])

  return <LinkComponent {...props} ref={ref} navigate={navigate} />
})

const PageLink = forwardRef<TouchableOpacity, NodeRendererProps<LinkNode>>((props, ref) => {
  const navigation = useNavigation()

  const { node, value } = props

  const navigate = useCallback(() => {
    const link = node.value?.link || value

    if (!link || typeof link !== 'string') {
      Alert.alert(`Cannot open link 'undefined'`)

      return
    }

    const navParams = node.api?.path && value ? { dataPath: node.api?.path, dataValue: value } : {}

    navigation.navigate(link, navParams)
  }, [node, value, navigation])

  return <LinkComponent {...props} ref={ref} navigate={navigate} />
})

export const LinkNodeRenderer = forwardRef<TouchableOpacity, NodeRendererProps<LinkNode>>(
  (props, ref) => {
    const pageKey = usePageKey()

    if (pageKey === 'root') {
      return <MenuLink {...props} ref={ref} />
    }

    return <PageLink {...props} ref={ref} />
  }
)
