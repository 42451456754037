import React, { forwardRef, useRef } from 'react'
import { Animated, ScrollView, StyleSheet } from 'react-native'
import { ScrollViewNode } from '@rapid/node-type'
import { LinearGradient } from 'expo-linear-gradient'
import { isColorGradient } from '@rapid/color'
import { useInjectMouseHandlers } from '../../util/injectMouseHandlers'
import { useResponsiveStyleKey } from '../../../../shared/hooks/useResponsiveStyleKey'
import { NodeRendererProps } from '../../types'
import { ScrollViewOffsetProvider } from '../context/ScrollViewOffset'

const styles = StyleSheet.create({
  background: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
    zIndex: -1,
  },
})

export const DefaultScrollView = forwardRef<ScrollView, NodeRendererProps<ScrollViewNode>>(
  ({ node, style, children }, ref) => {
    const scrollY = useRef(new Animated.Value(0)).current

    const editorProps = useInjectMouseHandlers(node)

    const responsiveStyleKey = useResponsiveStyleKey() ?? 'default'

    const maybeGradient = node.style?.[responsiveStyleKey]?.backgroundColor

    if (isColorGradient(maybeGradient)) {
      const {
        colors,
        locations,
        startEnd: { start, end },
      } = maybeGradient

      return (
        <ScrollView
          contentContainerStyle={style}
          style={{
            backgroundColor: style.backgroundColor,
          }}
          ref={ref}
          horizontal={node.value?.horizontal === true}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: {
                    y: scrollY,
                  },
                },
              },
            ],
            { useNativeDriver: false }
          )}
          scrollEventThrottle={32}
          {...editorProps}
        >
          <LinearGradient
            style={styles.background}
            colors={colors}
            locations={locations}
            start={start}
            end={end}
            {...editorProps}
          />
          <ScrollViewOffsetProvider scrollY={scrollY}>{children}</ScrollViewOffsetProvider>
        </ScrollView>
      )
    }

    return (
      <ScrollView
        contentContainerStyle={style}
        style={{
          backgroundColor: style.backgroundColor,
        }}
        ref={ref}
        horizontal={node.value?.horizontal === true}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: scrollY,
                },
              },
            },
          ],
          { useNativeDriver: false }
        )}
        scrollEventThrottle={32}
        {...editorProps}
      >
        <ScrollViewOffsetProvider scrollY={scrollY}>{children}</ScrollViewOffsetProvider>
      </ScrollView>
    )
  }
)
