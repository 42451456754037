import React, { forwardRef, useRef, useState } from 'react'
import { Animated, FlatList, StatusBar, StyleSheet } from 'react-native'
import { range } from 'ramda'
import { ScrollViewNode } from '@rapid/node-type'
import { isColorGradient } from '@rapid/color'
import { LinearGradient } from 'expo-linear-gradient'
import { useInjectMouseHandlers } from '../../util/injectMouseHandlers'
import { NodeRendererProps } from '../../types'
import { useResponsiveStyleKey } from '../../../../shared/hooks/useResponsiveStyleKey'
import { ScrollViewOffsetProvider } from '../context/ScrollViewOffset'

const initial = range(0, 20).map((n) => ({ id: String(n) }))

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
})

export const InfiniteScrollView = forwardRef<FlatList, NodeRendererProps<ScrollViewNode>>(
  ({ node, style, children }, ref) => {
    const [data, setData] = useState(initial)

    const scrollY = useRef(new Animated.Value(0)).current

    const editorProps = useInjectMouseHandlers(node)

    const responsiveStyleKey = useResponsiveStyleKey() ?? 'default'

    const maybeGradient = node.style?.[responsiveStyleKey]?.backgroundColor

    const renderItem = () => (
      <ScrollViewOffsetProvider scrollY={scrollY}>{children}</ScrollViewOffsetProvider>
    )

    if (isColorGradient(maybeGradient)) {
      const {
        colors,
        locations,
        startEnd: { start, end },
      } = maybeGradient

      return (
        <LinearGradient
          style={styles.container}
          colors={colors}
          locations={locations}
          start={start}
          end={end}
          {...editorProps}
        >
          <FlatList
            contentContainerStyle={style}
            style={{
              backgroundColor: style.backgroundColor,
            }}
            data={data}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            ref={ref}
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            horizontal={node.value?.horizontal === true}
            onEndReached={() => {
              setData([
                ...data,
                {
                  id: new Date().toJSON(),
                },
              ])
            }}
            onScroll={Animated.event(
              [
                {
                  nativeEvent: {
                    contentOffset: {
                      y: scrollY,
                    },
                  },
                },
              ],
              { useNativeDriver: false }
            )}
            scrollEventThrottle={32}
            {...editorProps}
          />
        </LinearGradient>
      )
    }
    return (
      <FlatList
        contentContainerStyle={style}
        style={{
          backgroundColor: style.backgroundColor,
        }}
        data={data}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ref={ref}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        horizontal={node.value?.horizontal === true}
        onEndReached={() => {
          setData([
            ...data,
            {
              id: new Date().toJSON(),
            },
          ])
        }}
        onScroll={Animated.event(
          [
            {
              nativeEvent: {
                contentOffset: {
                  y: scrollY,
                },
              },
            },
          ],
          { useNativeDriver: false }
        )}
        scrollEventThrottle={32}
        {...editorProps}
      />
    )
  }
)
