import React, { forwardRef } from 'react'
import { Video } from 'expo-av'
import { Platform } from 'react-native'
import { isVideoMimeType } from '@rapid/mime-type'
import { isFileRef } from '@rapid/util'
import { VideoNode } from '@rapid/node-type'
import { useResolveAssetPath } from '../../shared/context/AssetPath'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

type WrappedVideoNodeProps = Omit<
  NodeRendererProps<VideoNode>,
  'childNodes' | 'value' | 'children'
> & {
  uri: string
}

const NativeVideoNodeRenderer = forwardRef<Video, WrappedVideoNodeProps>(({ style, uri }, ref) => (
  <Video
    source={{ uri }}
    rate={1.0}
    isMuted={true}
    resizeMode="cover"
    shouldPlay
    isLooping
    style={style}
    ref={ref}
  />
))

const WebVideoNodeRenderer = forwardRef<HTMLVideoElement, WrappedVideoNodeProps>(
  ({ node, style, uri }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <video
        style={{ ...style, objectFit: 'cover' }}
        ref={ref}
        autoPlay
        loop
        muted
        {...editorProps}
      >
        <source src={uri} />
      </video>
    )
  }
)

export const VideoNodeRenderer = forwardRef<any, NodeRendererProps<VideoNode>>(
  ({ node, value, style }, ref) => {
    const resolveAssetPath = useResolveAssetPath()

    if (!isFileRef(value) || !isVideoMimeType(value?.mimeType)) {
      return null
    }

    const uri = resolveAssetPath(value.id, value.mimeType)

    if (!uri) {
      return null
    }

    if (Platform.OS === 'web') {
      return <WebVideoNodeRenderer node={node} style={style} uri={uri} ref={ref} />
    }

    return <NativeVideoNodeRenderer node={node} style={style} uri={uri} ref={ref} />
  }
)
