import { useCallback, useState } from 'react'
import { GestureResponderEvent } from 'react-native'

export const useBooleanValue = (
  initialValue = false
): [
  isTrue: boolean,
  setIsTrue: (_?: GestureResponderEvent) => void,
  setIsFalse: (_?: GestureResponderEvent) => void
] => {
  const [isTrue, setIsTrue] = useState(initialValue)

  const setTrue = useCallback((_?: GestureResponderEvent) => setIsTrue(true), [])

  const setFalse = useCallback((_?: GestureResponderEvent) => setIsTrue(false), [])

  return [isTrue, setTrue, setFalse]
}
