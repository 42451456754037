import React, { useEffect } from 'react'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { EditorRoutePagePayload, Message } from '@rapid/messenger'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { MenuNavigationProvider } from '../../../shared/context/MenuNavigation'
import { RootRenderer } from '../../../renderer/RootRenderer'
import { SelectionProvider } from '../lib/context/Selection'

export const NavigationDrawer = ({ navigation }: DrawerContentComponentProps) => {
  useEffect(() => {
    const handleEditorRoutePageMessage = (event: MessageEvent<EditorRoutePagePayload>) => {
      if (event.data.id === Message.EditorRoutePage && event.data.payload.id) {
        navigation.closeDrawer()
      } else if (event.data.id === Message.EditorRoutePage && !event.data.payload.id) {
        navigation.openDrawer()
      }
    }

    window.addEventListener('message', handleEditorRoutePageMessage, false)

    return () => window.removeEventListener('message', handleEditorRoutePageMessage)
  }, [navigation])

  return (
    <PageKeyProvider pageKey="root">
      <SelectionProvider>
        <MenuNavigationProvider navigation={navigation}>
          <RootRenderer />
        </MenuNavigationProvider>
      </SelectionProvider>
    </PageKeyProvider>
  )
}
