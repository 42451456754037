import React, { forwardRef } from 'react'
import { VictoryBar, VictoryChart, VictoryLegend, VictoryTheme } from 'victory-native'
import { Node } from '@rapid/core-types'
import { useChartData } from './util/useChartData'
import { NodeRendererProps } from './types'

export const ChartColumnNodeRenderer = forwardRef<any, NodeRendererProps<Node>>(
  ({ childNodes }: NodeRendererProps<Node>) => {
    const data = useChartData(childNodes)
    const legendArray = data.map(({ x, y, color }) => {
      return {
        name: x,
        y: y,
        symbol: { fill: !color ? '#0000FF' : color },
      }
    })
    return (
      <>
        {data.length > 0 ? (
          <VictoryChart domainPadding={21}>
            <VictoryLegend
              x={250}
              y={10}
              title={childNodes[0].title}
              centerTitle
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: '#000000' }, title: { fontSize: 20 } }}
              data={legendArray}
            />
            <VictoryBar
              data={data}
              style={{ data: { fill: ({ datum }) => datum.color } }}
              theme={VictoryTheme.material}
            />
          </VictoryChart>
        ) : (
          <VictoryChart domainPadding={21}>
            <VictoryLegend
              x={250}
              y={10}
              centerTitle
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: '#0000FF' }, title: { fontSize: 20 } }}
              data={[{ name: 'Sample', symbol: { fill: '#0000FF' } }]}
            />
            <VictoryBar
              data={[{ x: 'Sample', y: 20, color: '#0000FF', interpolation: '' }]}
              style={{ data: { fill: '#000000' } }}
              theme={VictoryTheme.material}
            />
          </VictoryChart>
        )}
      </>
    )
  }
)
