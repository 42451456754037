import React, { ReactNode, useContext } from 'react'
import { Animated } from 'react-native'

const ScrollOffsetContext = React.createContext<{ scrollY: Animated.Value } | null>(null)

export const ScrollViewOffsetProvider = ({
  scrollY,
  children,
}: {
  scrollY: Animated.Value
  children: ReactNode
}) => <ScrollOffsetContext.Provider value={{ scrollY }}>{children}</ScrollOffsetContext.Provider>

export const useScrollOffset = () => {
  const ctx = useContext(ScrollOffsetContext)

  return ctx ?? { scrollY: null }
}
