import { NodeTree } from '@rapid/core-types'
import { resolvePageDataContext } from '../../../shared/resolve/pageDataContext'
const { useFindNodeById } = resolvePageDataContext()

export const useChartLineData = (childNodes: NodeTree[]) => {
  if (childNodes === undefined) {
    return []
  }

  const updatedNodes: any[] = []
  childNodes.forEach((childNode: NodeTree) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const updatedNode = useFindNodeById(childNode.id)
    updatedNodes.push(updatedNode)
  })

  const data = updatedNodes.reduce<Array<{ x: string; y: number; color: any; interpolation: any }>>(
    (acc, dataPoint) => {
      const x = dataPoint?.value?.title
      const y = dataPoint?.value?.value
      const color = dataPoint?.style?.default.color

      const interpolation = dataPoint?.value

      acc.push({
        x,
        y,
        color,
        interpolation,
      })

      return acc
    },
    []
  )

  return data
}
