import { useWindowDimensions } from 'react-native'
import { useBreakpoint } from '../context/Breakpoint'

export const useResponsiveStyleKey = () => {
  const { width } = useWindowDimensions()

  const breakpointList = useBreakpoint()

  const match = breakpointList.find(({ minWidth }) => width === minWidth)

  return match?.id
}
