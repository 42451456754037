import React, { FC, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AsyncStorageKey } from '@rapid/app-state'
import { Favorite } from '@rapid/core-types'
import { usePageList as usePageListWeb } from '../../../entry/WebEditorApp/lib/context/PageList'
import { usePageList as usePageListApp } from '../../../entry/App/lib/context/PageList'
import { PageList } from '../../../types'

export const FavoritesContext = React.createContext<Favorite[] | undefined>(undefined)

const createFavoritesData = (storedFavorites: string, pages: PageList) => {
  const favoritesIds = JSON.parse(storedFavorites)

  if (favoritesIds && Array.isArray(favoritesIds)) {
    return favoritesIds.flatMap((id) => {
      const page = pages.find((p) => p.id === id)

      if (!page) {
        return []
      }

      return { id, title: page.title, description: page.description, value: page.value }
    })
  }

  return []
}

const getFavoriteData = async (setFavoriteData: (arg0: Favorite[]) => void, pages: PageList) => {
  const storedFavorites = await AsyncStorage.getItem(AsyncStorageKey.Favorites)

  if (storedFavorites) {
    setFavoriteData(createFavoritesData(storedFavorites, pages))
  }
}

export const FavoritesProviderWeb: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [favoriteData, setFavoriteData] = useState<Favorite[]>([])
  const pages = usePageListWeb()

  useEffect(() => {
    getFavoriteData(setFavoriteData, pages).catch(() => null)
  }, [pages])

  return <FavoritesContext.Provider value={favoriteData}>{children}</FavoritesContext.Provider>
}

export const FavoritesProviderApp: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [favoriteData, setFavoriteData] = useState<Favorite[]>([])
  const pages = usePageListApp()

  useEffect(() => {
    getFavoriteData(setFavoriteData, pages).catch(() => null)
  }, [pages])

  return <FavoritesContext.Provider value={favoriteData}>{children}</FavoritesContext.Provider>
}
