import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LayoutChangeEvent, View } from 'react-native'
import { SwiperFlatList } from 'react-native-swiper-flatlist'
import { SwiperNode } from '@rapid/node-type'
import { resolveSelectionContext } from '../../shared/resolve/selectionContext'
import { RecursiveRenderer } from '../RecursiveRenderer'
import { NodeRenderer } from '../NodeRenderer'
import { NodeRendererProps } from './types'

const { useSelectedNode } = resolveSelectionContext()

export const SwiperNodeRenderer = forwardRef<any, NodeRendererProps<SwiperNode>>(
  ({ childNodes, style }, _) => {
    const ref = useRef<SwiperFlatList>(null)

    const [childWidth, setChildWidth] = useState(0)

    const onLayoutChangeEvent = useCallback((e: LayoutChangeEvent) => {
      setChildWidth(e.nativeEvent.layout.width)
    }, [])

    const selectedNode = useSelectedNode()

    const index = useMemo(
      () =>
        childNodes.findIndex(
          ({ id }) => selectedNode && (selectedNode.id === id || selectedNode.parents.includes(id))
        ),
      [childNodes, selectedNode]
    )

    useEffect(() => {
      if (index === -1 || !ref.current) {
        return
      }

      if (ref.current.getCurrentIndex() === index) {
        return
      }

      ref.current.scrollToIndex({ index, animated: true })
    }, [index, ref])

    return (
      <SwiperFlatList
        ref={ref}
        style={style}
        index={index !== -1 ? index : null}
        onLayout={onLayoutChangeEvent}
      >
        {childWidth
          ? childNodes.map((node) => (
              <View key={node.id} style={{ width: childWidth }}>
                <NodeRenderer node={node}>
                  <RecursiveRenderer nodes={node.nodes} />
                </NodeRenderer>
              </View>
            ))
          : null}
      </SwiperFlatList>
    )
  }
)
