import React from 'react'
import { SetSelectedApp, usePreviouslyScannedApps } from '../../../setAppId'
import { AppList, Loader, Wrapper } from '../shared'
import { AppIdTextField } from './AppIdTextField'

export const AppSelectorSimulator = ({ setSelectedApp }: { setSelectedApp: SetSelectedApp }) => {
  const appList = usePreviouslyScannedApps()

  return appList ? (
    <Wrapper>
      <AppIdTextField setSelectedApp={setSelectedApp} />

      {appList.length ? <AppList appList={appList} setSelectedApp={setSelectedApp} /> : null}
    </Wrapper>
  ) : (
    <Loader />
  )
}
