import { PageType } from '@rapid/bible'
import { PageList } from '../../../../../types'
import { PageData } from '../../../types'

export const createPageList = (pageData: PageData) =>
  Object.values(pageData).reduce<PageList>((acc, page) => {
    if (page.main.type === PageType.Root) {
      return acc
    }

    acc.push({
      id: page.main.id,
      name: page.main.name,
      title: page.main.title,
      description: page.main.description,
      value: page.main.value,
    })

    return acc
  }, [])
