import { Alert, Share } from 'react-native'

export const shareModalHandler = async (text: string) => {
  try {
    const result = await Share.share({
      message: text,
    })
    if (result.action === Share.sharedAction) {
      if (result.activityType) {
        Alert.alert('Thank you!')
      } else {
        Alert.alert('Sorry, something went wrong')
      }
    } else if (result.action === Share.dismissedAction) {
      // dismissed
    }
  } catch (error) {
    Alert.alert(error.message)
  }
}
