import React, { FC, useContext } from 'react'
import { prop, sortBy } from 'ramda'
import { Breakpoint } from '@rapid/core-types'

const BreakpointContext = React.createContext<Breakpoint[] | undefined>(undefined)

export const BreakpointProvider: FC<{
  breakpointList: Breakpoint[]
  children: React.ReactNode
}> = ({ breakpointList, children }) => (
  <BreakpointContext.Provider value={breakpointList}>{children}</BreakpointContext.Provider>
)

export const useBreakpoint = () => {
  const ctx = useContext(BreakpointContext)

  if (!ctx) {
    throw new Error('No Breakpoint provider found')
  }

  return sortBy(prop('minWidth'), ctx)
}
