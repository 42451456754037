import React, { FC, useContext } from 'react'
import { PageList } from '../../../../../types'

const PageListContext = React.createContext<PageList | undefined>(undefined)

export const PageListProvider: FC<{ pageList: PageList; children: React.ReactNode }> = ({
  pageList,
  children,
}) => <PageListContext.Provider value={pageList}>{children}</PageListContext.Provider>

export const usePageList = () => {
  const ctx = useContext(PageListContext)

  if (!ctx) {
    throw new Error('PageListContext not in tree')
  }

  return ctx
}
