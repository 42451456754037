import React, { FC, useContext } from 'react'
import { NavigationApiParams } from '../../../types'

const NavigationApiParamsContext = React.createContext<NavigationApiParams | undefined>(undefined)

export const NavigationApiParamsProvider: FC<{
  navigationApiParams: NavigationApiParams
  children: React.ReactNode
}> = ({ navigationApiParams, children }) => (
  <NavigationApiParamsContext.Provider value={navigationApiParams}>
    {children}
  </NavigationApiParamsContext.Provider>
)

export const useNavigationApiParams = () => {
  const navigationApiParams = useContext(NavigationApiParamsContext)

  return navigationApiParams
}
