import React, { forwardRef, useCallback } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { InputFilePickerNode } from '@rapid/node-type'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { isWebEditorMode } from '../../shared/platform'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

const { useFormFieldChangeHandler } = resolveAppStateContext()

export const ProductionInputFilePickerNode = forwardRef<
  TouchableOpacity,
  NodeRendererProps<InputFilePickerNode>
>(({ node, style, children }, ref) => {
  const onChange = useFormFieldChangeHandler(node)

  const handleAction = useCallback(async () => {
    const result = await DocumentPicker.getDocumentAsync()

    if (result.type === 'success') {
      const base64 =
        Platform.OS === 'web'
          ? result.uri
          : await FileSystem.readAsStringAsync(result.uri, {
              encoding: FileSystem.EncodingType.Base64,
            })

      onChange({ data: base64, name: result.name })
    }
  }, [onChange])

  return (
    <TouchableOpacity style={style} ref={ref} onPress={handleAction}>
      {children}
    </TouchableOpacity>
  )
})

const PreviewInputFilePickerNode = forwardRef<View, NodeRendererProps<InputFilePickerNode>>(
  ({ node, style, children }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <View style={style} ref={ref} {...editorProps}>
        {children}
      </View>
    )
  }
)

export const InputFilePickerNodeRenderer = forwardRef<
  TouchableOpacity,
  NodeRendererProps<InputFilePickerNode>
>((props, ref) => {
  if (isWebEditorMode()) {
    return <PreviewInputFilePickerNode {...props} ref={ref} />
  }

  return <ProductionInputFilePickerNode {...props} ref={ref} />
})
