import React, { useEffect, useState } from 'react'
import { Alert, Text, TextInput, View } from 'react-native'
import {
  AppNameRequestBody,
  AppNameRequestParams,
  AppNameResponseData,
  AppNameResponseError,
} from '@rapid/request-types'
import { useDataRequest } from '../../../shared/context/DataRequest'
import { SetSelectedApp } from '../../../setAppId'

const objectIdRegex = /^[a-f\d]{24}$/i

export const AppIdTextField = ({ setSelectedApp }: { setSelectedApp: SetSelectedApp }) => {
  const [appIdString, setAppIdString] = useState('')

  const dataRequest = useDataRequest()

  useEffect(() => {
    const parseAppIdString = async () => {
      if (objectIdRegex.test(appIdString)) {
        const resp = await dataRequest.get<
          AppNameResponseData,
          AppNameResponseError,
          AppNameRequestParams,
          AppNameRequestBody
        >(`/public/app/name/${appIdString}`)

        if (resp.ok) {
          setSelectedApp({ id: appIdString, name: resp.data.name }).catch(() => null)
        } else {
          Alert.alert('App not found')
        }
      }
    }

    parseAppIdString().catch(() => {
      Alert.alert('Request failed')
    })
  }, [appIdString, setSelectedApp, dataRequest])

  return (
    <View
      style={{
        marginTop: 10,
        marginBottom: 20,
        marginHorizontal: 20,
      }}
    >
      <Text style={{ color: 'white', marginBottom: 5 }}>Enter App Id:</Text>

      <TextInput
        style={{
          height: 40,
          borderColor: 'gray',
          borderWidth: 1,
          color: 'white',
          paddingHorizontal: 5,
          borderRadius: 3,
        }}
        onChangeText={setAppIdString}
        value={appIdString}
      />
    </View>
  )
}
