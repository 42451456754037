import React, { forwardRef, useMemo, useRef } from 'react'
import MapView, { Marker } from 'react-native-maps'
import { MapNode } from '@rapid/node-type'
import { NodeRendererProps } from '../types'
import { isAddressValue } from './util'

export const MapNative = forwardRef<any, NodeRendererProps<MapNode>>(({ childNodes, style }, _) => {
  const mapRef = useRef<MapView>(null)

  const latLngList = useMemo(
    () =>
      childNodes.reduce<Array<{ longitude: number; latitude: number }>>((acc, childNode) => {
        const address = childNode.value?.address

        if (isAddressValue(address)) {
          acc.push({ longitude: address.lng, latitude: address.lat })
        }

        return acc
      }, []),
    [childNodes]
  )

  return (
    <MapView
      style={style}
      ref={mapRef}
      onMapReady={() => {
        mapRef.current?.fitToCoordinates(latLngList, {
          edgePadding: { top: 10, right: 50, bottom: 10, left: 50 },
          animated: false,
        })
      }}
    >
      {childNodes.map((childNode, index) => {
        const value = childNode.value

        const address = value?.address

        return isAddressValue(address) ? (
          <Marker
            key={index}
            coordinate={{ longitude: address.lng, latitude: address.lat }}
            title={value.name || address.name}
            description={value.description}
          />
        ) : null
      })}
    </MapView>
  )
})
