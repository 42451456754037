import { useContext } from 'react'
import { FavoritesContext } from './FavoritesProvider'

export const useFavorites = () => {
  const ctx = useContext(FavoritesContext)

  if (!ctx) {
    // throw new Error('FavoritesContext has not been mounted')
  }

  return ctx
}
