import { NodeName } from '@rapid/bible'
import { ActionNodeRenderer } from './Action'
import { ChartColumnNodeRenderer } from './ChartColumn'
import { ChartGaugeNodeRenderer } from './ChartGauge'
import { ChartLineGroupNodeRenderer } from './ChartLineGroup'
import { ChartPieNodeRenderer } from './ChartPie'
import { DateNodeRenderer } from './Date'
import { DefaultNodeRenderer } from './Default'
import { ImageNodeRenderer } from './Image'
import { InputDateTimePickerNodeRenderer } from './InputDateTimePicker'
import { InputFilePickerNodeRenderer } from './InputFilePicker'
import { InputPickerNodeRenderer } from './InputPicker'
import { InputRadioNodeRenderer } from './InputRadio'
import { InputRadioFieldsetNodeRenderer } from './InputRadioFieldset'
import { InputSwitchNodeRenderer } from './InputSwitch'
import { InputTextNodeRenderer } from './InputText'
import { LinkNodeRenderer } from './Link'
import { MapNodeRenderer } from './Map'
import { NumberNodeRenderer } from './Number'
import { ScrollViewNodeRenderer } from './ScrollView'
import { SwiperNodeRenderer } from './Swiper'
import { TextNodeRenderer } from './Text'
import { VideoNodeRenderer } from './Video'
import { ViewNodeRenderer } from './View'
import { WebViewNodeRenderer } from './WebView'
import { SearchBarNodeRenderer } from './SearchBar'

export const getNode = (nodeName: NodeName) => {
  switch (nodeName) {
    case 'Action':
      return ActionNodeRenderer
    case 'ChartColumn':
      return ChartColumnNodeRenderer
    case 'ChartGauge':
      return ChartGaugeNodeRenderer
    case 'ChartLineGroup':
      return ChartLineGroupNodeRenderer
    case 'ChartPie':
      return ChartPieNodeRenderer
    case 'Date':
      return DateNodeRenderer
    case 'Image':
      return ImageNodeRenderer
    case 'InputDateTimePicker':
      return InputDateTimePickerNodeRenderer
    case 'InputFilePicker':
      return InputFilePickerNodeRenderer
    case 'InputPicker':
      return InputPickerNodeRenderer
    case 'InputRadio':
      return InputRadioNodeRenderer
    case 'InputRadioFieldset':
      return InputRadioFieldsetNodeRenderer
    case 'InputRadioGroup':
      return ViewNodeRenderer
    case 'InputSwitch':
      return InputSwitchNodeRenderer
    case 'InputText':
      return InputTextNodeRenderer
    case 'Label':
      return TextNodeRenderer
    case 'Link':
      return LinkNodeRenderer
    case 'Map':
      return MapNodeRenderer
    case 'Number':
      return NumberNodeRenderer
    case 'ScrollView':
      return ScrollViewNodeRenderer
    case 'Swiper':
      return SwiperNodeRenderer
    case 'Text':
      return TextNodeRenderer
    case 'Video':
      return VideoNodeRenderer
    case 'View':
      return ViewNodeRenderer
    case 'WebView':
      return WebViewNodeRenderer
    case 'SearchBar':
      return SearchBarNodeRenderer
    default:
      return DefaultNodeRenderer
  }
}
