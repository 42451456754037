import React, { forwardRef } from 'react'
import { Text } from 'react-native'
import { NumberNode } from '@rapid/node-type'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const NumberNodeRenderer = forwardRef<Text, NodeRendererProps<NumberNode>>(
  ({ node, value, style }, ref) => (
    <Text style={style} ref={ref} {...useInjectMouseHandlers(node)}>
      {value}
    </Text>
  )
)
