import React, { FC } from 'react'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { Request } from '@rapid/fetch'
import { ApiMapperProvider } from '../context/ApiMapper'
import { AssetPathProvider } from '../context/AssetPath'
import { BreakpointProvider } from '../context/Breakpoint'
import { DataRequestProvider } from '../context/DataRequest'
import { HeaderProvider } from '../context/Header'
import { StyleTransformationProvider } from '../context/StyleTransformation'
import { AppData } from '../../entry/WebEditorApp/types'
import { env } from '../../env'
import { refactorLocalhostBaseUrl } from '../../entry/App/lib/hooks/util'

const request = new Request({ baseUrl: refactorLocalhostBaseUrl(env.API_URL) })

export const SharedAppProvider: FC<
  Omit<AppData, 'rootPage' | 'pageData' | 'pageList'> & { children: React.ReactNode }
> = ({ breakpointList, servicesById, fontList, requestsById, header, children }) => (
  <SafeAreaProvider>
    <StatusBar />

    <AssetPathProvider baseUrl={refactorLocalhostBaseUrl(env.API_URL)}>
      <StyleTransformationProvider fontList={fontList}>
        <DataRequestProvider request={request}>
          <ApiMapperProvider servicesById={servicesById} requestsById={requestsById}>
            <BreakpointProvider breakpointList={breakpointList}>
              <HeaderProvider header={header}>{children}</HeaderProvider>
            </BreakpointProvider>
          </ApiMapperProvider>
        </DataRequestProvider>
      </StyleTransformationProvider>
    </AssetPathProvider>
  </SafeAreaProvider>
)
