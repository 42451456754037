import React, { forwardRef, useEffect, useMemo } from 'react'
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet'
import { NodeTree } from '@rapid/core-types'
import { isNode } from '@rapid/util'
import { MapNode } from '@rapid/node-type'
import { usePageNodeMap } from '../../../entry/WebEditorApp/lib/context/PageData'
import { useSelectedNode } from '../../../entry/WebEditorApp/lib/context/Selection'
import { NodeRendererProps } from '../types'
import { isAddressValue } from './util'

const MapContent = ({ childNodes }: { childNodes: NodeTree[] }) => {
  const map = useMap()

  const nodeMap = usePageNodeMap()

  const firstChildNode = nodeMap?.[childNodes[0]?.id]

  const selectedNode = useSelectedNode()

  const centerPoint = useMemo<[number, number]>(() => {
    if (selectedNode && isAddressValue(selectedNode.value?.address)) {
      return [selectedNode.value.address.lat, selectedNode.value.address.lng]
    }

    if (isNode(firstChildNode) && isAddressValue(firstChildNode.value?.address)) {
      return [firstChildNode.value.address.lat, firstChildNode.value.address.lng]
    }

    return [51.505, -0.09]
  }, [firstChildNode, selectedNode])

  useEffect(() => {
    map.setView(centerPoint, 13)
  }, [map, centerPoint])

  return (
    <>
      <TileLayer
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="mapbox/streets-v11"
        accessToken="pk.eyJ1IjoicmFwaWRlc3R1ZGlvIiwiYSI6ImNraml0cjU3NDFkNm4yeWxvN2dhbHJtbmUifQ.rLN52gPONhEy6KkhJGittg"
        tileSize={512}
        zoomOffset={-1}
      />

      {childNodes.map((childNode) => {
        const addressNode = nodeMap?.[childNode.id]

        return isNode(addressNode) && isAddressValue(addressNode.value?.address) ? (
          <Marker
            key={addressNode.id}
            position={[addressNode.value.address.lat, addressNode.value.address.lng]}
          />
        ) : null
      })}
    </>
  )
}

export const MapWeb = forwardRef<any, NodeRendererProps<MapNode>>(({ childNodes, style }, _) => {
  const key = style.width && style.height ? `${style.width}+${style.height}` : 'no-res'

  return (
    <MapContainer
      center={[51.505, -0.09]}
      zoom={13}
      scrollWheelZoom={false}
      zoomControl={false}
      style={style}
      key={key}
    >
      <MapContent childNodes={childNodes} />
    </MapContainer>
  )
})
