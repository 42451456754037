import React, { ReactNode } from 'react'
import { SafeAreaView } from 'react-native'

export const Wrapper = ({ children }: { children: ReactNode }) => (
  <SafeAreaView
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#2f2f33',
    }}
  >
    {children}
  </SafeAreaView>
)
