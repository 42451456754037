/** @jsxRuntime classic /
/** @jsx jsx */
import { MutableRefObject, useEffect, useState } from 'react'
import { findDOMNode } from 'react-dom'
import { css, jsx, keyframes } from '@emotion/react'
import { Node } from '@rapid/core-types'
import { useIsNodeSelected } from '../../entry/WebEditorApp/lib/context/Selection'

const highlight = keyframes`
  0% {
    border-color: rgba(62, 169, 245, 0.5);
  }
  50% {
    border-color: rgba(62, 169, 245, 1);
  }
  100% {
    border-color: rgba(62, 169, 245, 0.5);
  }
`

const getDOMNode = (ref: MutableRefObject<any>) => {
  try {
    // eslint-disable-next-line react/no-find-dom-node
    return findDOMNode(ref.current)
  } catch (_) {
    return undefined
  }
}

const resizeNode = (domNode?: HTMLElement) => {
  const parentNode = domNode?.parentNode

  if (domNode instanceof HTMLElement && parentNode instanceof HTMLElement) {
    const domRect = domNode.getBoundingClientRect()

    const parentDomRect = parentNode.getBoundingClientRect()

    const top = domRect.top - parentDomRect.top

    const left = domRect.left - parentDomRect.left

    return {
      width: domRect.width,
      height: domRect.height,
      top,
      left,
    }
  }
}

export const Highlight = ({
  node,
  componentRef,
}: {
  node: Node
  componentRef: MutableRefObject<any>
}) => {
  const [positionProps, setPositionProps] = useState<{
    width?: number
    height?: number
    top?: number
    left?: number
  }>()

  const isNodeSelected = useIsNodeSelected(node)

  useEffect(() => {
    const domNode = getDOMNode(componentRef)

    if (domNode instanceof HTMLElement) {
      const observer = new MutationObserver(() => {
        const result = resizeNode(domNode)

        setPositionProps(result)
      })

      observer.observe(domNode, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true,
      })

      const resizeObserver = new window.ResizeObserver(() => {
        const result = resizeNode(domNode)

        setPositionProps(result)
      })

      resizeObserver.observe(domNode)

      return () => {
        observer.disconnect()

        resizeObserver.unobserve(domNode)
      }
    }
  }, [componentRef, isNodeSelected])

  return positionProps ? (
    <div
      style={{
        display: isNodeSelected ? 'block' : 'none',
        position: 'absolute',
        zIndex: 1,
        borderColor: 'white',
        borderWidth: 1,
        borderStyle: 'dashed',
        pointerEvents: 'none',
        boxSizing: 'border-box',
        ...positionProps,
      }}
      css={css`
        animation: ${highlight} 2s ease infinite;
      `}
    />
  ) : null
}
