import { path } from 'ramda'
import { AppState } from '@rapid/app-state'
import { NodeWithStateMapping } from './types'
import { createUnmappedValue } from './resolveApiResponseValue'

export const resolveStateMappedValue = (
  node: NodeWithStateMapping,
  state: AppState
): {
  isArray: false
  value: any
} => {
  const stateDataPath = node.state.path.split('.')

  const value = path(stateDataPath, state)

  if (value) {
    return {
      isArray: false,
      value,
    }
  }

  return createUnmappedValue(node)
}
