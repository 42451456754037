import React, { forwardRef } from 'react'
import { Image } from 'react-native'
import { ImageNode } from '@rapid/node-type'
import { AppSafeStyleMap } from '@rapid/style-helpers'
import { useInjectMouseHandlers } from '../util/injectMouseHandlers'

export const StaticImage = forwardRef<
  Image,
  { node: ImageNode; uri: string; style: Partial<AppSafeStyleMap> }
>(({ node, uri, style }, ref) => {
  const editorProps = useInjectMouseHandlers(node)

  return <Image source={{ uri }} ref={ref} style={style} {...editorProps} />
})
