import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Request } from '@rapid/fetch'
import {
  ValidRequestConfig,
  createRequestConfig,
  createRequestPayload,
  isValidRequestConfig,
  parseRequestDataString,
} from '@rapid/api-mapper'
import { NodeTree } from '@rapid/core-types'
import { resolveAppStateContext } from '../../resolve/appStateContext'
import { resolvePageDataContext } from '../../resolve/pageDataContext'
import { ApiResponseProviderProps, ApiResponseType } from './types'

const request = new Request()

const { useAppState } = resolveAppStateContext()

export const ApiResponseContext = React.createContext<ApiResponseType | undefined>(undefined)

export const ApiResponseProvider: FC<ApiResponseProviderProps> = ({
  page,
  requestsById,
  children,
}) => {
  const [{ isLoading, apiResponseData }, setApiResponseData] = useState<ApiResponseType>({
    isLoading: false,
  })

  const { usePageNodeTree } = resolvePageDataContext()

  const pageTree = usePageNodeTree()

  const searchNode = pageTree?.main.nodes

  let queryParams = ''
  let searchFormValue = ''

  const findNode = (arr: NodeTree[], val: string): NodeTree | undefined => {
    for (const obj of arr) {
      if (obj.name === val) {
        return obj
      }
      if (obj.nodes) {
        const searchBarValue = findNode(obj.nodes, val)
        if (searchBarValue) {
          return searchBarValue
        }
      }
    }
    return undefined
  }

  if (searchNode !== undefined) {
    const resultNode = findNode(searchNode, 'SearchBar')
    if (resultNode) {
      queryParams = resultNode?.value?.queryName
      searchFormValue = resultNode?.id
    }
  }

  const { appState } = useAppState()

  const searchPageData = Object.values(appState?.page)

  const searchParamData = useMemo(() => {
    if (searchPageData && searchFormValue) {
      return searchPageData.find((pageData) => pageData[searchFormValue]?.value)
    }
  }, [searchPageData, searchFormValue])
  const searchParam = searchParamData ? searchParamData[searchFormValue]?.value : null

  const requestId = page?.api?.requestId

  const requestConfig = useMemo(() => {
    if (!requestsById || !requestId) {
      return
    }

    return requestsById[requestId]
  }, [requestsById, requestId])

  const fetchApiData = useCallback(
    async (config: ValidRequestConfig) => {
      setApiResponseData({ isLoading: true })

      const createdConfig = createRequestConfig(config)

      const body = createRequestPayload(parseRequestDataString(config.body), appState)
      const headers = createRequestPayload(parseRequestDataString(config.headers), appState)

      const resp = await request.makeRequest(config.url, {
        ...createdConfig,
        credentials: 'omit',
        body,
        headers,
      })

      if (resp.ok) {
        setApiResponseData({ isLoading: false, apiResponseData: resp.data })
      } else {
        setApiResponseData({ isLoading: false })
      }
    },
    [appState]
  )

  useEffect(() => {
    if (!requestConfig) {
      return setApiResponseData({ isLoading: false })
    }

    if (requestConfig.api?.isSearchApi && requestConfig.api.url && searchParam && queryParams) {
      requestConfig.api.url = requestConfig.api.url?.split('?')[0]
      requestConfig.api.url = `${requestConfig.api.url}?${queryParams}=${searchParam}`
    }
    const config = requestConfig.api

    if (!config || !isValidRequestConfig(config)) {
      return setApiResponseData({ isLoading: false })
    }

    fetchApiData(config).catch(() => null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestConfig, searchParam])

  return (
    <ApiResponseContext.Provider
      value={{
        apiResponseData,
        isLoading,
        requestConfig,
      }}
    >
      {children}
    </ApiResponseContext.Provider>
  )
}
