import { Platform } from 'react-native'
import { env } from '../env'

export const isEditorMode = () => env.IS_EDITOR_MODE

export const isWebEditorMode = () => {
  if (Platform.OS !== 'web') {
    return false
  }

  return env.IS_EDITOR_MODE
}
