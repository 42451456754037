import React from 'react'
import { ApiResponseProvider } from '../shared/context/ApiResponse'
import { FavoritesProviderApp, FavoritesProviderWeb } from '../shared/context/Favorites'
import { useApiMapper } from '../shared/context/ApiMapper'
import { resolvePageDataContext } from '../shared/resolve/pageDataContext'
import { Favorites } from '../shared/page/Favorites'
import { isWebEditorMode } from '../shared/platform'
import { RecursiveRenderer } from './RecursiveRenderer'

const { usePageNodeTree } = resolvePageDataContext()

export const RootRenderer = () => {
  const tree = usePageNodeTree()

  const apiMapper = useApiMapper()

  const children =
    tree?.main.name === 'Favorites' && !tree?.main.favorites?.mapper ? (
      <Favorites tree={tree} />
    ) : tree?.main.nodes ? (
      <RecursiveRenderer nodes={tree.main.nodes} />
    ) : null

  const FavoritesProvider = isWebEditorMode() ? (
    <FavoritesProviderWeb>{children}</FavoritesProviderWeb>
  ) : (
    <FavoritesProviderApp>{children}</FavoritesProviderApp>
  )

  return (
    <ApiResponseProvider page={tree?.main} {...apiMapper}>
      {FavoritesProvider}
    </ApiResponseProvider>
  )
}
