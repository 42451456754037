import { useHeader } from '../context/Header'
import { useStyleFontMap } from '../context/StyleTransformation'

export const useNavigationHeaderConfig = () => {
  const headerConfig = useHeader()
  const fontMap = useStyleFontMap()

  if (!headerConfig || !headerConfig.enabled) {
    return { headerShown: false }
  }

  const headerFontFamily = headerConfig.fontFamily ? fontMap[headerConfig.fontFamily] : null

  return {
    headerShown: true,
    headerStyle: {
      backgroundColor: headerConfig.backgroundColor,
    },
    headerTintColor: headerConfig.tintColor,
    headerTitleAlign: headerConfig.textAlign,
    headerTitleStyle: {
      fontFamily: headerFontFamily,
      fontSize: headerConfig.fontSize,
    },
  }
}
