import React from 'react'
import { useBooleanValue } from '../../../shared/hooks/useBooleanValue'
import { SetSelectedApp, usePreviouslyScannedApps } from '../../../setAppId'
import { AppList, Loader, Wrapper } from '../shared'
import { QRButton } from './QRButton'
import { QRScanner } from './QRScanner'

export const AppSelectorRealDevice = ({ setSelectedApp }: { setSelectedApp: SetSelectedApp }) => {
  const [isQRScannerOpen, openQRScanner] = useBooleanValue(false)

  const appList = usePreviouslyScannedApps()

  return appList ? (
    isQRScannerOpen ? (
      <QRScanner setSelectedApp={setSelectedApp} />
    ) : (
      <Wrapper>
        <QRButton openQRScanner={openQRScanner} />

        {appList.length ? <AppList appList={appList} setSelectedApp={setSelectedApp} /> : null}
      </Wrapper>
    )
  ) : (
    <Loader />
  )
}
