import React, { forwardRef, useMemo } from 'react'
import { max, nth } from 'ramda'
import { View } from 'react-native'
import { InputRadioNode, isInputRadioGroupNode } from '@rapid/node-type'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { resolvePageDataContext } from '../../shared/resolve/pageDataContext'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

const { useFindNodeById } = resolvePageDataContext()

const { useFormFieldValue } = resolveAppStateContext()

export const InputRadioNodeRenderer = forwardRef<any, NodeRendererProps<InputRadioNode>>(
  ({ node, style }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    const parentId = nth(-2, node.parents)

    if (!parentId) {
      throw new Error('Cannot find parent id in `parents` list')
    }

    const parentNode = useFindNodeById(parentId)

    if (!isInputRadioGroupNode(parentNode)) {
      throw new Error('`parentNode` is not of type `InputRadioGroup`')
    }

    const value = useFormFieldValue(parentNode)

    const width = max(20, Number(style?.width))

    const transformedStyleObject = useMemo(() => {
      return {
        ...style,
        width,
        height: width,
        borderRadius: width / 2,
        borderColor: style.color,
        borderWidth: 3,
        justifyContent: 'center' as const,
        alignItems: 'center' as const,
      }
    }, [width, style])

    const dotStyleObject = useMemo(() => {
      return {
        ...style,
        width: width - 12,
        height: width - 12,
        borderRadius: (width - 12) / 2,
        backgroundColor: style.color ?? 'rgb(0, 0, 0)',
      }
    }, [width, style])

    return (
      <View style={transformedStyleObject} ref={ref} {...editorProps}>
        {value === node.value?.value ? <View style={dotStyleObject} /> : null}
      </View>
    )
  }
)
