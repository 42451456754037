import React, { FC, useCallback, useContext } from 'react'
import { MimeTypes } from '@rapid/mime-type'
import { resolveAssetPath } from '@rapid/util'

const AssetPathContext = React.createContext<
  | {
      resolveAssetPath: (id: string, mimeType: MimeTypes) => string
    }
  | undefined
>(undefined)

export const AssetPathProvider: FC<{ baseUrl: string; children: React.ReactNode }> = ({
  baseUrl,
  children,
}) => {
  const boundResolveAssetPath = useCallback(
    (id: string, mimeType: MimeTypes) => resolveAssetPath(baseUrl, id, mimeType),
    [baseUrl]
  )

  return (
    <AssetPathContext.Provider
      value={{
        resolveAssetPath: boundResolveAssetPath,
      }}
    >
      {children}
    </AssetPathContext.Provider>
  )
}

export const useResolveAssetPath = () => {
  const ctx = useContext(AssetPathContext)

  if (!ctx) {
    throw new Error('AssetPathProvider not in tree')
  }

  return ctx.resolveAssetPath
}
