import React, { FC, useContext, useEffect, useReducer } from 'react'
import { InsertPagePayload, Message } from '@rapid/messenger'
import { PageList } from '../../../../../types'
import { pageListReducer } from './reducer'

const PageListContext = React.createContext<PageList | undefined>(undefined)

export const PageListProvider: FC<{ pageList: PageList; children: React.ReactNode }> = ({
  pageList: initialPageList,
  children,
}) => {
  const [pageList, dispatch] = useReducer(pageListReducer, initialPageList)

  useEffect(() => {
    window.addEventListener(
      'message',
      (event: MessageEvent<InsertPagePayload>) => {
        if (event.data.id === Message.InsertPage) {
          dispatch({ type: 'append', data: event.data.payload })
        }
      },
      false
    )
  }, [])

  return <PageListContext.Provider value={pageList}>{children}</PageListContext.Provider>
}

export const usePageList = () => {
  const ctx = useContext(PageListContext)

  if (!ctx) {
    throw new Error('PageListContext not in tree')
  }

  return ctx
}
