import React, { forwardRef } from 'react'
import { InputPickerNode } from '@rapid/node-type'
import { useInjectMouseHandlers } from '../util/injectMouseHandlers'
import { NodeRendererProps } from '../types'

export const InputPickerWeb = forwardRef<any, NodeRendererProps<InputPickerNode>>(
  ({ node, style }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <select style={style} ref={ref} {...editorProps}>
        {node.value?.items?.map(({ label, value }) => (
          <option key={value}>{label}</option>
        ))}
      </select>
    )
  }
)
