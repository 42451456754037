import { Alert, Platform } from 'react-native'
import { mergeDeepRight } from 'ramda'
import { ActionAPIRequest, PageTreeByViewKey, Request } from '@rapid/core-types'
import { ValidationError, createRequestPayload, parseRequestDataString } from '@rapid/api-mapper'
import { AppState } from '@rapid/app-state'
import { ApiMapperDataType } from '../../../shared/context/ApiMapper'

const mergeRequestData = (
  prop: 'body' | 'headers' | 'params',
  action: ActionAPIRequest,
  request?: Request
) => {
  const requestDataFromConfig = parseRequestDataString(request?.api?.[prop])
  const requestDataFromAction = parseRequestDataString(action[prop])
  return mergeDeepRight(requestDataFromConfig, requestDataFromAction)
}

export const makeAPIRequest = (
  action: ActionAPIRequest,
  state: AppState,
  apiMapperData: ApiMapperDataType,
  pageTree?: PageTreeByViewKey
) => {
  const request = apiMapperData.requestsById[action.requestId]
  if (!request.api?.url) {
    return
  }

  try {
    const body = createRequestPayload(
      mergeRequestData('body', action, request),
      state,
      pageTree,
      true
    )
    // const params = cleanOutput(replaceTokens(mergeRequestData('params', action, request), state))
    const headers = createRequestPayload(
      mergeRequestData('headers', action, request),
      state,
      pageTree
    )

    return fetch(request.api.url, {
      body: JSON.stringify(body),
      headers,
      method: request.api.method,
    })
  } catch (e) {
    if (e instanceof ValidationError) {
      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-alert
        alert(e.message)
      } else {
        Alert.alert('Validation Error', e.message)
      }
    } else {
      throw e
    }
  }
}
