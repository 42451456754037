import { Alert, Platform } from 'react-native'

// eslint-disable-next-line @typescript-eslint/naming-convention
export const alert = (action: any) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-alert
    window.alert(action.message?.value)
  } else {
    Alert.alert(action.title?.value, action.message?.value, [{ text: 'OK' }])
  }
}
