import 'react-native-gesture-handler'
import React, { useEffect } from 'react'
import { registerRootComponent } from 'expo'
// import { AppState, Platform } from 'react-native'
// import * as Device from 'expo-device'
// import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
// import { Request } from '@rapid/fetch'
import { isWebEditorMode } from './shared/platform'
import { env } from './env'
import { createShakeHandler } from './entry/App/lib/shakeSensor'
import { AppSelector } from './entry/AppSelector'
import { WebEditorApp } from './entry/WebEditorApp'
import { App } from './entry/App'
import { useAppSelect } from './setAppId'

/*
 * Added for Push Notifications in Agora app 2023
 * Disabling until we have fully featured implementation for all apps on Rapid
 */
/*
Notifications.setNotificationHandler({
  // eslint-disable-next-line @typescript-eslint/require-await
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
})

async function registerForPushNotificationsAsync() {
  if (Platform.OS === 'android') {
    await Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#6101EB',
    })
  }

  if (Device.isDevice) {
    const request = new Request()
    const { status: existingStatus } = await Notifications.getPermissionsAsync()
    let finalStatus = existingStatus

    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync()
      finalStatus = status
    }
    if (finalStatus !== 'granted') {
      return
    }
    const token = (await Notifications.getExpoPushTokenAsync()).data

    if (token) {
      await request.makeRequest(`${env.API_URL}/query/push-token`, {
        method: 'POST',
        credentials: 'omit',
        body: {
          appId: env.APP_ID,
          pushToken: token,
        },
      })
    }
  }
}
*/

const AppEntry = () => {
  const [selectedApp, setSelectedApp] = useAppSelect()

  /*
   * Added for Push Notifications in Agora app 2023
   * Disabling until we have fully featured implementation for all apps on Rapid
   */
  /*

  useEffect(() => {
    const getToken = async () => {
      const request = new Request()
      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus

      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        return
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data

      if (token) {
        await request.makeRequest(`${env.API_URL}/query/push-token`, {
          method: 'POST',
          credentials: 'omit',
          body: {
            appId: env.APP_ID,
            pushToken: token,
          },
        })
      }
    }

    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (
        appState.current.match(/inactive|background/) &&
        nextAppState === 'active' &&
        Platform.OS === 'android'
      ) {
        // eslint-disable-next-line no-console
        getToken().catch(console.error)
      }

      appState.current = nextAppState
    })

    return () => {
      subscription.remove()
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    registerForPushNotificationsAsync()

    notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
      setNotification(notification)
    })

    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      console.log(response)
    })

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current)
      Notifications.removeNotificationSubscription(responseListener.current)
    }
  }, [])
  */

  useEffect(() => {
    if (Constants.appOwnership === 'expo' || env.APP_ID) {
      return
    }

    const removeShakeEventListener = createShakeHandler(() => {
      setSelectedApp(undefined).catch(() => null)
    })

    return () => removeShakeEventListener()
  }, [setSelectedApp])

  if (isWebEditorMode()) {
    const searchParams = new URLSearchParams(window.location.search)

    const appId = searchParams.get('appId')

    if (!appId) {
      throw new Error('Could not get appId from URL search params')
    }

    return <WebEditorApp appId={appId} />
  }

  if (env.APP_ID) {
    return <App appId={env.APP_ID} />
  }

  if (selectedApp) {
    return <App appId={selectedApp.id} />
  }

  return <AppSelector setSelectedApp={setSelectedApp} />
}

// eslint-disable-next-line import/no-default-export
export default isWebEditorMode() || __DEV__ ? registerRootComponent(AppEntry) : AppEntry
