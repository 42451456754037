/* eslint-disable @typescript-eslint/no-var-requires */
import { Platform } from 'react-native'

let MapComponent

if (Platform.OS === 'web') {
  MapComponent = require('./Map/Map.web').MapWeb
} else {
  MapComponent = require('./Map/Map.native').MapNative
}

export const MapNodeRenderer = MapComponent
