import React, { forwardRef, useCallback } from 'react'
import { TouchableOpacity, View } from 'react-native'
import openMap from 'react-native-open-maps'
import { useNavigation } from '@react-navigation/native'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { ActionNode } from '@rapid/node-type'
import { ActionName } from '@rapid/bible'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { resolvePageDataContext } from '../../shared/resolve/pageDataContext'
import { useApiMapper } from '../../shared/context/ApiMapper'
import { isWebEditorMode } from '../../shared/platform'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { openUrl } from './action/openUrl'
import { makeAPIRequest } from './action/makeAPIRequest'
import { alert } from './action/alert'
import { shareModalHandler } from './action/shareModalHandler'
import { NodeRendererProps } from './types'
import { addToCalendar } from './action/addToCalendar'
import { addToFavorite } from './action/addToFavorite'
import { removeFromFavorite } from './action/removeFromFavorite'
import { useIsPageFavorited } from './util/useIsPageFavorited'

const isDrawerNavigation = (navigation: any): navigation is DrawerNavigationProp<any> =>
  navigation.toggleDrawer != null

const { useAppState } = resolveAppStateContext()

const { usePageNodeTree } = resolvePageDataContext()

const hasAction = (node: any, actionName: string) => {
  if (!node.action || !Array.isArray(node.action)) {
    return false
  }

  for (const actionUnit of node.action) {
    if (actionUnit.enabled && Array.isArray(actionUnit.steps)) {
      for (const action of actionUnit.steps) {
        if (action.name === actionName) {
          return true
        }
      }
    }
  }
}

const ProductionActionNode = forwardRef<TouchableOpacity, NodeRendererProps<ActionNode>>(
  // eslint-disable-next-line sonarjs/cognitive-complexity
  ({ node, value, style, children }, ref) => {
    const navigation = useNavigation<BottomTabNavigationProp<any> | DrawerNavigationProp<any>>()

    const { appState } = useAppState()

    const pageTree = usePageNodeTree()

    const apiMapperData = useApiMapper()

    const isPageFavorited = useIsPageFavorited(node.ancestor)

    const handleAction = useCallback(async () => {
      if (!node.action || !Array.isArray(node.action)) {
        return
      }

      for (const actionUnit of node.action) {
        if (actionUnit.enabled && Array.isArray(actionUnit.steps)) {
          for (const action of actionUnit.steps) {
            if (action.name === ActionName.APIRequest) {
              const resp = await makeAPIRequest(action, appState, apiMapperData, pageTree)
              if (!resp) {
                break
              }
              if (resp?.status && resp.status >= 300) {
                alert({
                  title: { value: 'Error' },
                  message: { value: 'Request failed' },
                })

                break
              }
            } else if (action.name === ActionName.AddToCalendar) {
              await addToCalendar(action)
            } else if (action.name === ActionName.AddToFavorites) {
              await addToFavorite(action.pageId)
            } else if (action.name === ActionName.RemoveFromFavorites) {
              await removeFromFavorite(action.pageId)
            } else if (action.name === ActionName.Alert) {
              alert(action)
            } else if (action.name === ActionName.Authenticate) {
              navigation.navigate('oauth2', {
                serviceId: action.serviceId,
                pageId: action.pageId,
              })
            } else if (action.name === ActionName.ContactEmail && action.email.type === 'static') {
              await openUrl(`mailto:${action.email.value}`)
            } else if (action.name === ActionName.ContactPhone && action.phone.type === 'static') {
              await openUrl(`tel:${action.phone.value}`)
            } else if (action.name === ActionName.ContactSMS && action.phone.type === 'static') {
              await openUrl(`sms:${action.phone.value}`)
            } else if (action.name === ActionName.OpenInternalLink) {
              navigation.navigate(action.pageId)
            } else if (
              action.name === ActionName.OpenMapWithDirectionsToLocation &&
              action.location.type === 'static'
            ) {
              openMap({
                end: action.location?.value?.name,
              })
            } else if (
              action.name === ActionName.OpenNativeShareDialog &&
              action.message.type === 'static' &&
              action.message.value
            ) {
              await shareModalHandler(action.message.value)
            } else if (action.name === ActionName.OpenPreviousPage) {
              navigation.goBack()
            } else if (action.name === ActionName.OpenQrReader) {
              navigation.navigate('qr-code-scanner')
            } else if (
              action.name === ActionName.OpenWebUrl &&
              action.url.type === 'static' &&
              action.url.value === 'API' &&
              value
            ) {
              await openUrl(String(value))
            } else if (action.name === ActionName.OpenWebUrl && action.url.type === 'static') {
              await openUrl(action.url.value)
            } else if (action.name === ActionName.ToggleMenu && isDrawerNavigation(navigation)) {
              navigation.toggleDrawer()
            }
          }
        }
      }
    }, [node, value, appState, apiMapperData, navigation, pageTree])

    if (hasAction(node, ActionName.AddToFavorites) && isPageFavorited) {
      return null
    }

    if (hasAction(node, ActionName.RemoveFromFavorites) && !isPageFavorited) {
      return null
    }

    return (
      <TouchableOpacity style={style} ref={ref} onPress={handleAction}>
        {children}
      </TouchableOpacity>
    )
  }
)

const PreviewActionNode = forwardRef<TouchableOpacity, NodeRendererProps<ActionNode>>(
  ({ node, style, children }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    return (
      <View style={style} ref={ref} {...editorProps}>
        {children}
      </View>
    )
  }
)

export const ActionNodeRenderer = forwardRef<TouchableOpacity, NodeRendererProps<ActionNode>>(
  (props, ref) => {
    if (isWebEditorMode()) {
      return <PreviewActionNode {...props} ref={ref} />
    }

    return <ProductionActionNode {...props} ref={ref} />
  }
)
