import React, { forwardRef } from 'react'
import { Picker } from '@react-native-picker/picker'
import { InputPickerNode } from '@rapid/node-type'
import { resolveAppStateContext } from '../../../shared/resolve/appStateContext'
import { NodeRendererProps } from '../types'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

export const InputPickerNative = forwardRef<any, NodeRendererProps<InputPickerNode>>(
  ({ node, style }, _ref) => {
    const onChange = useFormFieldChangeHandler(node)

    const value = useFormFieldValue(node)

    return (
      <Picker selectedValue={value} onValueChange={onChange} style={style}>
        <Picker.Item key="" label="" value="" />
        {node.value?.items?.map((option) => (
          <Picker.Item key={option.value} label={option.label} value={option.value ?? ''} />
        ))}
      </Picker>
    )
  }
)
