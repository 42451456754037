import { assoc, pick } from 'ramda'
import { NodeMap, PageTreeByViewKey } from '@rapid/core-types'
import { PageDataMap } from './types'

export const pageDataReducer = (
  pageMap: PageDataMap,
  action:
    | { type: 'reset'; pageId: string; nodeMap: NodeMap; nodeTree: PageTreeByViewKey }
    | { type: 'empty' }
) => {
  if (action.type === 'reset') {
    return assoc(action.pageId, { nodeMap: action.nodeMap, nodeTree: action.nodeTree }, pageMap)
  }

  if (action.type === 'empty') {
    return pick(['root'], pageMap)
  }

  throw new Error('Unknown action')
}
