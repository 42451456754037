import { Alert, Linking } from 'react-native'

export const openUrl = async (url: string) => {
  const isSupported = await Linking.canOpenURL(url)

  if (isSupported) {
    await Linking.openURL(url)
  } else {
    Alert.alert(`Don't know how to open this URL: ${url}`)
  }
}
