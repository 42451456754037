import { useMemo } from 'react'
import { Node } from '@rapid/core-types'
import { sendOnClickNode } from '@rapid/messenger'
import { isWebEditorMode } from '../../../shared/platform'

const injectMouseHandlers = (node: Node) => {
  if (!isWebEditorMode()) {
    return undefined
  }

  const onClick = (e: MouseEvent) => {
    e.stopPropagation()

    sendOnClickNode(node)
  }

  return {
    onClick,
  }
}

export const useInjectMouseHandlers = (node: Node) =>
  useMemo(() => injectMouseHandlers(node), [node])
