import React, { useMemo } from 'react'
import * as Linking from 'expo-linking'
import { NavigationContainer } from '@react-navigation/native'
import { BottomTabBarProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer'
import { isRootPage } from '@rapid/util'
import { NavigationName, PageName } from '@rapid/bible'
import { defaultScreens } from '../../shared/defaultScreens'
import { NavigationDrawer, NavigationTabBar } from './Navigation'
import { PageTypeRenderer } from './Page'
import { useRootLinks } from './lib/hooks/useRootLinks'
import { useRootNodeTree } from './lib/context/PageData'

const prefix = Linking.createURL('/')

export const Navigator = ({ navigationName }: { navigationName: NavigationName | PageName }) => {
  const links = useRootLinks()

  const root = useRootNodeTree()

  const initialRouteName = useMemo(() => {
    // Don't get initialRouteName for editor app
    if (!root || !isRootPage(root.main)) {
      return
    }

    return root.main.meta?.landingPageId ?? 'placeholder'
  }, [root])

  const linking = useMemo(
    () => ({
      prefixes: [prefix],
      config: {
        screens: {
          Chat: 'feed/:sort',
          Profile: 'user',
        },
      },
    }),
    []
  )

  const [Navigation, navigationProps] = useMemo(() => {
    switch (navigationName) {
      case NavigationName.Tab:
        return [
          createBottomTabNavigator(),
          { tabBar: (props: BottomTabBarProps) => <NavigationTabBar {...props} /> },
        ]
      case NavigationName.Drawer:
        return [
          createDrawerNavigator(),
          {
            drawerContent: (props: DrawerContentComponentProps) => <NavigationDrawer {...props} />,
          },
        ]
      default:
        throw new Error('Unknown root page type')
    }
  }, [navigationName])

  return (
    <NavigationContainer linking={linking}>
      <Navigation.Navigator
        initialRouteName={initialRouteName}
        screenOptions={{ headerShown: false }}
        {...navigationProps}
      >
        {links.length ? (
          <>
            {links.map((link) => (
              <Navigation.Screen
                key={link.id}
                name={link.id}
                component={PageTypeRenderer}
                options={{ title: link.name }}
              />
            ))}

            {defaultScreens.map(([name, Component]) => (
              <Navigation.Screen key={name} name={name} component={Component} />
            ))}
          </>
        ) : (
          <Navigation.Screen name="placeholder" component={PageTypeRenderer} />
        )}
      </Navigation.Navigator>
    </NavigationContainer>
  )
}
