import React, { FC, useContext } from 'react'
import { Request, Service } from '@rapid/core-types'

export interface ApiMapperDataType {
  servicesById: Record<string, Service>
  requestsById: Record<string, Request>
}

const ApiMapperContext = React.createContext<ApiMapperDataType | undefined>(undefined)

export const ApiMapperProvider: FC<ApiMapperDataType & { children: React.ReactNode }> = ({
  servicesById,
  requestsById,
  children,
}) => (
  <ApiMapperContext.Provider value={{ servicesById, requestsById }}>
    {children}
  </ApiMapperContext.Provider>
)

export const useApiMapper = () => {
  const ctx = useContext(ApiMapperContext)

  if (!ctx) {
    throw new Error('No api mapper provider found')
  }

  return ctx
}
