import React from 'react'
import { View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { PageTree } from '@rapid/core-types'
import { RouteParams } from '../../../types'
import { NavigationListener } from '../lib/context/NavigationListener'
import { SelectionProvider } from '../lib/context/Selection'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { RootRenderer } from '../../../renderer/RootRenderer'

const Tab = createMaterialTopTabNavigator()

const TabBar = ({ route }: { route: RouteProp<RouteParams, 'Default'> }) => (
  <PageKeyProvider pageKey={route.name}>
    <SelectionProvider>
      <RootRenderer />
    </SelectionProvider>
  </PageKeyProvider>
)

const EmptyPage = () => <View />

export const TabPage = ({
  route,
}: {
  route: RouteProp<RouteParams, 'Default'>
  tree: PageTree
}) => (
  <NavigationListener>
    <Tab.Navigator tabBar={() => <TabBar route={route} />}>
      <Tab.Screen name="Home" component={EmptyPage} />
    </Tab.Navigator>
  </NavigationListener>
)
