import React, { forwardRef } from 'react'
import { Platform, SafeAreaView, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { isColorGradient } from '@rapid/color'
import { ViewNode } from '@rapid/node-type'
import { useResponsiveStyleKey } from '../../shared/hooks/useResponsiveStyleKey'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

export const ViewNodeRenderer = forwardRef<any, NodeRendererProps<ViewNode>>(
  ({ node, style, children }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    const responsiveStyleKey = useResponsiveStyleKey() ?? 'default'

    const maybeGradient = node.style?.[responsiveStyleKey]?.backgroundColor

    if (isColorGradient(maybeGradient)) {
      const {
        colors,
        locations,
        startEnd: { start, end },
      } = maybeGradient

      return node.value?.isSafeAreaView && Platform.OS === 'ios' ? (
        <SafeAreaView>
          <LinearGradient
            style={style}
            ref={ref}
            colors={colors}
            locations={locations}
            start={start}
            end={end}
            {...editorProps}
          >
            {children}
          </LinearGradient>
        </SafeAreaView>
      ) : (
        <LinearGradient
          style={style}
          ref={ref}
          colors={colors}
          locations={locations}
          start={start}
          end={end}
          {...editorProps}
        >
          {children}
        </LinearGradient>
      )
    }

    return node.value?.isSafeAreaView && Platform.OS === 'ios' ? (
      <SafeAreaView style={style} ref={ref} {...editorProps}>
        {children}
      </SafeAreaView>
    ) : (
      <View style={style} ref={ref} {...editorProps}>
        {children}
      </View>
    )
  }
)
