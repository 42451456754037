import React, { forwardRef } from 'react'
import { TextInput } from 'react-native'
import { InputTextNode } from '@rapid/node-type'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'
import { rgbToHex } from './util/rgbToHex'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

export const InputTextNodeRenderer = forwardRef<any, NodeRendererProps<InputTextNode>>(
  ({ node, value, style }, ref) => {
    const onChange = useFormFieldChangeHandler(node)
    const defaultValue = useFormFieldValue(node)

    const hexColor = rgbToHex(style?.placeholderTextColor)

    // Extract the placeholderTextColor property from the style object
    const { placeholderTextColor: _, ...restStyle } = style || {}

    return (
      <TextInput
        style={restStyle}
        placeholder={value?.placeholder}
        onChangeText={onChange}
        ref={ref}
        placeholderTextColor={hexColor}
        defaultValue={defaultValue}
        {...useInjectMouseHandlers(node)}
      />
    )
  }
)
