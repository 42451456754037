import React, { forwardRef } from 'react'
import { VictoryLegend, VictoryPie, VictoryTheme } from 'victory-native'
import { Node } from '@rapid/core-types'
import { useChartData } from './util/useChartData'
import { NodeRendererProps } from './types'

export const ChartPieNodeRenderer = forwardRef<any, NodeRendererProps<Node>>(
  ({ childNodes }: NodeRendererProps<Node>) => {
    const data = useChartData(childNodes)
    const legendArray = data.map(({ x, y, color }) => {
      return {
        name: x,
        y: y,
        symbol: { fill: !color ? '#0000FF' : color },
      }
    })

    return (
      <>
        <VictoryPie
          data={data}
          style={{ data: { fill: ({ datum }) => (datum.color ? datum.color : '#0000FF') } }}
          theme={VictoryTheme.material}
        />
        <VictoryLegend
          x={100}
          y={0}
          centerTitle
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: 'black' }, title: { fontSize: 20 } }}
          data={legendArray}
        />
      </>
    )
  }
)
