import React, { forwardRef } from 'react'
import { ScrollViewNode } from '@rapid/node-type'
import { NodeRendererProps } from '../types'
import { DefaultScrollView } from './DefaultScrollView/DefaultScrollView'
import { InfiniteScrollView } from './InfiniteScrollView/InfiniteScrollView'

export const ScrollViewNodeRenderer = forwardRef<any, NodeRendererProps<ScrollViewNode>>(
  ({ node, ...props }, ref) => {
    if (node.value?.infinite === true) {
      return <InfiniteScrollView ref={ref} node={node} {...props} />
    }

    return <DefaultScrollView ref={ref} node={node} {...props} />
  }
)
