import React, { forwardRef } from 'react'
import { Text } from 'react-native'
import { format, formatDistanceToNow, parse } from 'date-fns'
import { DateNode } from '@rapid/node-type'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'

const isDate = (value: any): value is Date => value instanceof Date

const getDateValue = (value: DateNode['value']) => {
  if (!value) {
    throw new Error('Cannot parse value')
  }

  const { value: dateValue, inputFormat } = value

  if (isDate(dateValue)) {
    return dateValue
  }

  if (typeof dateValue === 'string' && typeof inputFormat === 'string') {
    return parse(dateValue, inputFormat, new Date())
  }

  throw new Error('Cannot parse value')
}

const getFormattedDate = (value: DateNode['value']) => {
  const dateValue = getDateValue(value)

  if (value?.humanize === true) {
    return formatDistanceToNow(dateValue, { addSuffix: true })
  }

  if (typeof value?.outputFormat === 'string') {
    return format(dateValue, value.outputFormat)
  }

  return dateValue.toString()
}

export const DateNodeRenderer = forwardRef<Text, NodeRendererProps<DateNode>>(
  ({ node, value, style }, ref) => {
    const editorProps = useInjectMouseHandlers(node)

    let formattedDate: string

    try {
      formattedDate = getFormattedDate(value)
    } catch (_) {
      formattedDate = 'Invalid Date'
    }

    return (
      <Text style={style} ref={ref} {...editorProps}>
        {formattedDate}
      </Text>
    )
  }
)
