import React from 'react'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { MenuNavigationProvider } from '../../../shared/context/MenuNavigation'
import { RootRenderer } from '../../../renderer/RootRenderer'

export const NavigationTabBar = ({ state, descriptors, navigation }: BottomTabBarProps) => {
  const currentRoute = descriptors[state.routes[state.index].key]

  if (currentRoute.options.tabBarVisible === false) {
    return null
  }

  return (
    <PageKeyProvider pageKey="root">
      <MenuNavigationProvider navigation={navigation}>
        <RootRenderer />
      </MenuNavigationProvider>
    </PageKeyProvider>
  )
}
