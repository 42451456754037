import React, { forwardRef, useCallback, useMemo } from 'react'
import { nth } from 'ramda'
import { TouchableOpacity } from 'react-native'
import { InputRadioFieldsetNode, isInputRadioGroupNode, isInputRadioNode } from '@rapid/node-type'
import { NodeName } from '@rapid/bible'
import { isWebEditorMode } from '../../shared/platform'
import { resolvePageDataContext } from '../../shared/resolve/pageDataContext'
import { resolveAppStateContext } from '../../shared/resolve/appStateContext'
import { useInjectMouseHandlers } from './util/injectMouseHandlers'
import { NodeRendererProps } from './types'
import { DefaultNodeRenderer } from './Default'

const { useFindNodeById } = resolvePageDataContext()

const { useFormFieldChangeHandler } = resolveAppStateContext()

export const ProductionInputRadioFieldsetNode = forwardRef<
  any,
  NodeRendererProps<InputRadioFieldsetNode>
>(({ node, style, childNodes, children }, ref) => {
  const editorProps = useInjectMouseHandlers(node)

  const parentId = nth(-1, node.parents)

  if (!parentId) {
    throw new Error('Cannot find parent id in `parents` list')
  }

  const childNode = useMemo(() => childNodes.find(({ name }) => name === NodeName.InputRadio), [
    childNodes,
  ])

  const parentNode = useFindNodeById(parentId)

  if (!isInputRadioGroupNode(parentNode)) {
    throw new Error('`parentNode` is not of type `InputRadioGroup`')
  }

  if (!isInputRadioNode(childNode)) {
    throw new Error('`childNode` is not of type `InputRadio`')
  }

  const onChange = useFormFieldChangeHandler(parentNode)

  const handleAction = useCallback(() => onChange(childNode.value?.value), [onChange, childNode])

  return (
    <TouchableOpacity style={style} ref={ref} onPress={handleAction} {...editorProps}>
      {children}
    </TouchableOpacity>
  )
})

export const InputRadioFieldsetNodeRenderer = forwardRef<
  any,
  NodeRendererProps<InputRadioFieldsetNode>
>((props, ref) => {
  if (isWebEditorMode()) {
    return <DefaultNodeRenderer {...props} ref={ref} />
  }

  return <ProductionInputRadioFieldsetNode {...props} ref={ref} />
})
