import { Favorite } from '@rapid/core-types'
import { NodeWithFavoritesConfig, ResolvedDataValue } from './types'

export const resolveFavoriteValue = (
  node: NodeWithFavoritesConfig,
  iterationIndex: number | undefined,
  storedFavorites: Favorite[]
): ResolvedDataValue => {
  if (iterationIndex !== undefined && typeof node.favorites.field === 'string') {
    const field = node.favorites.field

    return {
      isArray: false,
      path: 'favorites',
      value:
        storedFavorites && Array.isArray(storedFavorites) && storedFavorites.length > 0
          ? storedFavorites[iterationIndex][field]
          : null,
    }
  }

  return {
    isArray: true,
    path: 'favorites',
    length: storedFavorites.length,
  }
}
