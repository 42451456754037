import React from 'react'
import { RouteProp } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { PageTreeByViewKeyWithLinks } from '@rapid/core-types'
import { PageKeyProvider } from '../../../shared/context/PageKey'
import { NavigationApiParamsProvider } from '../../../shared/context/NavigationApiParams'
import { RouteParams } from '../../../types'
import { RootRenderer } from '../../../renderer/RootRenderer'
import { usePageLinks } from '../lib/hooks/usePageLinks'
import { useNavigationHeaderConfig } from '../../../shared/hooks/useNavigationHeaderConfig'
import { QRCodeScanner } from './special/QRCodeScanner'

const Stack = createStackNavigator()

const PageProviders = ({ route }: { route: RouteProp<RouteParams, 'Default'> }) => (
  <PageKeyProvider pageKey={route.name}>
    <NavigationApiParamsProvider navigationApiParams={route.params}>
      <RootRenderer />
    </NavigationApiParamsProvider>
  </PageKeyProvider>
)

export const DefaultPage = ({
  route,
  tree,
}: {
  route: RouteProp<RouteParams, 'Default'>
  tree: PageTreeByViewKeyWithLinks
}) => {
  const links = usePageLinks(route.name)
  const navigationHeaderConfig = useNavigationHeaderConfig()

  return (
    <Stack.Navigator initialRouteName={route.name} screenOptions={navigationHeaderConfig}>
      <Stack.Screen
        name={tree.main.id}
        component={PageProviders}
        options={{ title: tree.main.title }}
      />

      {links.map((link) => (
        <Stack.Screen
          key={link.id}
          name={link.id}
          component={PageProviders}
          options={{ title: link.name }}
        />
      ))}

      <Stack.Screen name="qr-code-scanner" component={QRCodeScanner} />
    </Stack.Navigator>
  )
}
