import { PageList } from '../../../../../types'

export const pageListReducer = (
  pageList: PageList,
  action: { type: 'append'; data: PageList[number] }
): PageList => {
  if (action.type === 'append') {
    return [...pageList, action.data]
  }

  throw new Error('Unknown action')
}
