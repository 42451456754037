import { useCallback, useEffect, useState } from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { AsyncStorageKey } from '@rapid/app-state'

export interface App {
  id: string
  name: string
}

export type SetSelectedApp = (app: App | undefined) => Promise<void>

export const useAppSelect = (): [App | undefined, SetSelectedApp] => {
  const [selectedApp, setSelectedApp] = useState<App | undefined>()

  const setSelectedAppAndAddToStorage = useCallback(async (app: App | undefined) => {
    setSelectedApp(app)

    if (app) {
      const JSONString = await AsyncStorage.getItem(AsyncStorageKey.ScannedApps)

      const appList: App[] = JSONString ? JSON.parse(JSONString) : []

      const filteredAppList = appList.filter(({ id }) => app.id !== id)

      await AsyncStorage.setItem(
        AsyncStorageKey.ScannedApps,
        JSON.stringify([app, ...filteredAppList])
      )
    }
  }, [])

  return [selectedApp, setSelectedAppAndAddToStorage]
}

export const usePreviouslyScannedApps = () => {
  const [appList, setAppList] = useState<App[] | undefined>()

  useEffect(() => {
    AsyncStorage.getItem(AsyncStorageKey.ScannedApps)
      .then((JSONString) => setAppList(JSONString ? JSON.parse(JSONString) : []))
      .catch(() => setAppList([]))
  }, [])

  return appList
}
