import React, { FC, useContext } from 'react'
import { Request } from '@rapid/fetch'

const DataRequestContext = React.createContext<{
  request?: Request
}>({})

export const DataRequestProvider: FC<{ request: Request; children: React.ReactNode }> = ({
  request,
  children,
}) => <DataRequestContext.Provider value={{ request }}>{children}</DataRequestContext.Provider>

export const useDataRequest = () => {
  const { request } = useContext(DataRequestContext)

  if (!request) {
    throw new Error('Make sure DataRequest is in context')
  }

  return request
}
