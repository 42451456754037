import React from 'react'
import { RouteProp } from '@react-navigation/native'
import { PageName } from '@rapid/bible'
import { RouteParams } from '../../../types'
import { usePageDataContext } from '../lib/context/PageData'
import { DefaultPage } from './DefaultPage'
import { TabPage } from './TabPage'

const pageNames = Object.values(PageName)

const isPageName = (name: any): name is PageName => pageNames.includes(name)

export const getPage = (nodeName: PageName) => {
  switch (nodeName) {
    case 'Default':
      return DefaultPage
    case 'TabPage':
      return TabPage
    default:
      return DefaultPage
  }
}

export const PageTypeRenderer = ({ route }: { route: RouteProp<RouteParams, 'Default'> }) => {
  const pageDataContext = usePageDataContext()

  const pageData = pageDataContext?.[route.name]

  const tree = pageData?.nodeTree

  if (!tree || !isPageName(tree?.main.name)) {
    return null
  }

  const PageComponent = getPage(tree.main.name)

  return <PageComponent route={route} tree={tree.main} />
}
