import React, { forwardRef, useCallback, useRef } from 'react'
import {
  GestureResponderEvent,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import DateTimePicker from '@react-native-community/datetimepicker'
import { InputDateTimePickerNode } from '@rapid/node-type'
import { parseJSON } from 'date-fns'
import { useBooleanValue } from '../../../shared/hooks/useBooleanValue'
import { resolveAppStateContext } from '../../../shared/resolve/appStateContext'
import { NodeRendererProps } from '../types'

const { useFormFieldChangeHandler, useFormFieldValue } = resolveAppStateContext()

const now = new Date()

const styles = StyleSheet.create({
  modalContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  modalView: {
    width: '80%',
    height: 330,
    padding: 10,
    backgroundColor: 'white',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.25,
    shadowRadius: 10,
    borderRadius: 5,
  },
})

export const InputDateTimePickerIOS = forwardRef<any, NodeRendererProps<InputDateTimePickerNode>>(
  ({ node, style }, ref) => {
    const modalOverlayRef = useRef(null)

    const [isDatePickerShown, showDatePicker, hideDatePicker] = useBooleanValue()

    const onChange = useFormFieldChangeHandler(node)

    const value = useFormFieldValue(node)

    const handleOnChange = useCallback((_, selectedDate) => onChange(selectedDate), [onChange])

    const handleModalOnTap = useCallback(
      (e: GestureResponderEvent) => {
        if (modalOverlayRef?.current === e.target) {
          hideDatePicker()
        }
      },
      [hideDatePicker]
    )

    return (
      <>
        <TouchableOpacity style={style} ref={ref} onPress={showDatePicker}>
          <Text>
            {value instanceof Date
              ? value.toLocaleDateString()
              : typeof value === 'string'
              ? parseJSON(value).toLocaleDateString()
              : ''}
          </Text>
        </TouchableOpacity>

        <Modal
          visible={isDatePickerShown}
          animationType="fade"
          transparent={true}
          onRequestClose={hideDatePicker}
        >
          <TouchableWithoutFeedback onPress={handleModalOnTap}>
            <View style={styles.modalContainer} ref={modalOverlayRef}>
              <View style={styles.modalView}>
                <DateTimePicker
                  value={value || now}
                  mode="date"
                  display="inline"
                  style={{
                    flex: 1,
                  }}
                  onChange={handleOnChange}
                />
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </>
    )
  }
)
