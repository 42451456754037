import React, { forwardRef } from 'react'
import { ImageNode } from '@rapid/node-type'
import { isFileRef } from '@rapid/util'
import { useResolveAssetPath } from '../../shared/context/AssetPath'
import { AnimatedImage, ParallaxImage, StaticImage } from './Image/index'
import { NodeRendererProps } from './types'

const httpRegex = /^https?:\/\//

const isValidURL = (v: any): v is string => typeof v === 'string' && httpRegex.test(v)

const useResolveFileUri = (value: ImageNode['value']) => {
  const resolveAssetPath = useResolveAssetPath()

  if (isFileRef(value)) {
    return resolveAssetPath(value.id, value.mimeType)
  }

  if (isValidURL(value)) {
    return value
  }

  return null
}

export const ImageNodeRenderer = forwardRef<any, NodeRendererProps<ImageNode>>(
  ({ node, value, style }, ref) => {
    const uri = useResolveFileUri(value)

    if (!uri) {
      return null
    }

    if (node.meta?.parallax?.enabled === true) {
      return <ParallaxImage uri={uri} node={node} ref={ref} style={style} />
    }

    return node.meta?.fadeIn.enabled === true ? (
      <AnimatedImage uri={uri} node={node} ref={ref} style={style} />
    ) : (
      <StaticImage uri={uri} node={node} ref={ref} style={style} />
    )
  }
)
